import React from "react";
import { Route, Redirect } from "react-router-dom";
import store from "../_helper/helper.store";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let { isLoggedIn } = store.getState().user;
      if (isLoggedIn) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }
    }}
  />
);
export default PrivateRoute;
