export const IS_DEVEOPMENT = false;
export const AppVersion = '1.0.14';
const DevelopmentURL = 'https://staging.gokampus.com/';
const ProductionURL = 'https://www.gokampus.com/';
const DevelopmentApiURL = 'https://dev-api.gokampus.com/';
const ProductionApiURL = 'https://wapiprod.azurewebsites.net/';


export const BASE_URL = IS_DEVEOPMENT ? DevelopmentURL : ProductionURL;
export const API_URL = IS_DEVEOPMENT
  ? DevelopmentApiURL  //+ 'apidev/'
  : ProductionApiURL //+ 'api/';
export const OPEN_TOKEN = IS_DEVEOPMENT
  ? 'WxhZTy4BXQUyAYn9'
  : 'PNgPaxLEmS6QBHuV';
export const GOOGLEKEY = IS_DEVEOPMENT
?'AIzaSyBv_2-0KJc4FiI4ubpC5kvp4QESAvnD7L8'
:'AIzaSyBNUAZD906sC6lRC7oRhMLatVTYYadW9Hs';

export const USER_TYPE = 'university';

export const NAV_STRINGS = {
  signup: 'signup',
  dataEntry: 'deverify',
  login: 'login',
  changePassword: 'CHANGE_PASSWORD',
  externalAccess: 'EXTERNAL_ACCESS',
  reports: 'REPORTS',
  users: 'USERS',
  universityCMS: 'UNIVERSITY_CMS',
  studentList: 'STUDENT_LIST',
  dashboard: 'DASHBOARD',
};

export const USER_ROLE = {
  admin: /Administrator/i,
  manager: /manager/i,
  dataEntry: /dataentry/i,
};

const VALIDATION_STRING = 'validation.';
const API_STRING = 'apiMessages.';
export const TRANS_STRING = {
  COMMON_STRING: 'commonText.',
  API_STRING,
  VALIDATION_STRING,
  SAVED_STRING: API_STRING + 'saved',
  INPUT_REQ: VALIDATION_STRING + 'required',
  INPUT_INVALID: VALIDATION_STRING + 'invalid',
};

export const MASTER_NAMES =
  'ProgramFee,UniversityProgram,UniversityProgramType,' +
  'Province,Country,Accreditation,ProgramType,StudyLevel,StudyType,ProgramLanguage,Duration,MediaType,City,CampusSchoolType,Blog API URL';

export const MEDIA_TYPE_IMAGE = /image/i;

export const Colors = {
  primary: '#5E35B1',
  secondary: '#F68E58',
  grey: '#707070',
  dark: '#4D4F5C',
  tableHeadColor: '#F5F6FA',
  // backgroundColor: "#E9E9F0"
};

export const ImageTypes = {
  portrait: 'portrait',
  landscape: 'landscape',
};
