import {
  generateRequiredFormDataFields,
  generateFetchRequest
} from "../../_helper/apiMethods";
import store from "../../_helper/helper.store";

export const List = () => {
  let {
    user: { password }
  } = store.getState();
  let formData = generateRequiredFormDataFields({
    signatureKey: password.universityUID
  });
  formData.set("universityUID", password.universityUID);

  let url = "/university/user/list";
  return generateFetchRequest(formData, url);
};

export const Add = formData => {
  let {
    user: { password }
  } = store.getState();
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: password.universityUID
  });
  formData.append("universityUID", password.universityUID);

  let url = "/university/user/add";
  return generateFetchRequest(formData, url);
};

export const Update = (formData, uid) => {
  let {
    user: { password }
  } = store.getState();
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: uid
  });
  formData.append("uid", uid);
  formData.append("universityUID", password.universityUID);

  let url = "/university/user/update";
  return generateFetchRequest(formData, url);
};

export const PasswordReset = (uid, email) => {
  let formData = generateRequiredFormDataFields({ signatureKey: uid });
  formData.append("userUID", uid);
  formData.append("email", email);

  let url = "/University/user/pasword/reset";
  return generateFetchRequest(formData, url);
};
