import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import PreviewIcon from '@material-ui/icons/RemoveRedEye';
import { t } from '../../_language';
import {
  CustomTableHead,
  RenderColumnData,
} from '../../component/tableComponents';
import { MasterAPI } from '../../_api';

const PAGE_STRING = 'studentList.';

const AttachmentList = ({ list }) => {
  if (list.length === 0)
    return (
      <Typography variant="subtitle2" align="center">
        {t(PAGE_STRING + 'attachmentEmpty')}
      </Typography>
    );
  const columnData = [
    { id: 'attachType', label: t(PAGE_STRING + 'attachType') },
    { id: 'mediaType', label: t(PAGE_STRING + 'mediaType') },
    { id: 'createdAt', label: t(PAGE_STRING + 'createdAt') },
  ];
  const onPressPreviewAttachment = async (item) => {
    let res = await MasterAPI.AccessBlob(item.attachURL);

    if (res) {
      window.open(res.url, '_blank');
    }
  };

  const renderListItem = (item) => {
    return (
      <TableRow key={item.uid}>
        {columnData.map(RenderColumnData(item))}
        <TableCell>
          <IconButton onClick={() => onPressPreviewAttachment(item)}>
            <PreviewIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <Table>
      <CustomTableHead columnData={columnData} />
      <TableBody>{list.map(renderListItem)}</TableBody>
    </Table>
  );
};
export default AttachmentList;
