import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";

import {
  Drawer,
  AppBar,
  Toolbar,
  MenuItem,
  Typography,
  IconButton,
  Menu
} from "@material-ui/core";
import MenuItems from "./Menu";
import { styles } from "../styles";
import { removeUser } from "../_reducers/reducer.user";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { t } from "../_language";
import { NAV_STRINGS } from "../Const";

const PAGE_STRING = "header.";
class TitleBar extends React.PureComponent {
  getUserFullName = "";
  constructor(props) {
    super(props);
    let { password } = props.user;
    this.getUserFullName = `${password.firstname || ""} ${password.lastname ||
      ""}`;
    this.state = {
      open: true,
      anchor: "left",
      anchorEl: null
    };
  }

  //handleDrawerOpen = () => {
  //    this.setState({ open: true });
  //};

  handleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  logout = () => {
    removeUser();
  };
  changePassword = () => {
    this.props.history.push(NAV_STRINGS.changePassword);
    this.setState({ anchorEl: null });
  };
  //onSelectLanguage = (lang) => {
  //    setLanguage(lang);
  //    this.setState({
  //        anchorLang: null
  //    })
  //}
  //handleLanguage = (event) => {
  //    this.setState({
  //        anchorLang: event.currentTarget
  //    })
  //}
  render() {
    const { classes } = this.props;
    const { anchor, open, anchorEl } = this.state;
    const menuopen = Boolean(anchorEl);
    return (
      <div className={classes.root}>
        <AppBar position="absolute" elevation={4} className={classes.appBar}>
          <Toolbar>
            <Link target="_blank" to={{ pathname: NAV_STRINGS.dashboard }}  style={{display: "flex", padding: "10px 0"}}>
              <img
                src={"/assets/images/logo.png"}
                style={{ height: 40 }}
                alt="goKampus"
              />
            </Link>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={this.handleDrawer}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon classes={{ root: classes.menuIcon }} />
            </IconButton>
            <div className={classes.flex} />
            <Typography variant="caption">
              {t(PAGE_STRING + "hello")} {this.getUserFullName}
            </Typography>
            <IconButton
              aria-owns={menuopen ? "menu-appbar" : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={menuopen}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.changePassword}>
                {t(PAGE_STRING + "changePassword")}
              </MenuItem>
              <MenuItem onClick={this.logout}>
                {t(PAGE_STRING + "logout")}
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="persistent"
          anchor={anchor}
          open={open}
          classes={{
            paper: classes.drawerPaper,
            modal: classes.drawerModal
          }}
        >
          <div className={classes.toolbar} />

          <MenuItems />
        </Drawer>
        <main
          className={classNames(classes.content, classes[`content-${anchor}`], {
            [classes.contentShift]: open,
            [classes[`contentShift-${anchor}`]]: open
          })}
        >
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

TitleBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const StyledTitleBar = withStyles(styles, { withTheme: true })(TitleBar);

const mapStateToProps = state => ({
  user: state.user
});
export default connect(mapStateToProps)(StyledTitleBar);
