import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
const Header = () => {
  return (
    <AppBar
      position="absolute"
      elevation={4}
      style={{ backgroundColor: "#fff" }}
    >
      <Toolbar>
        <Typography variant="subtitle2" color="inherit">
          <Link to={{ pathname: "/" }} style={{display: "flex", padding: "10px 0"}}>
            <img
              src={"/assets/images/logo.png"}
              style={{ height: 40 }}
              alt="goKampus"
              className={"logoImg"}
            />
          </Link>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
