import React from "react";
import { Route } from 'react-router-dom';
import UserList from './UserList'
import UserDetails from './UserDetails'

class User extends React.Component {

    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route exact path={`${match.path}`} component={UserList} />
                <Route exact path={`${match.path}/add`} component={UserDetails} />
                <Route exact path={`${match.path}/update`} component={UserDetails} />
            </React.Fragment >
        )
    }
}
export default User
// const mapDispatchToProps = dispatch => bindActionCreators({
//     storeMaster
// }, dispatch)
// const mapStateToProps = state => ({
//     user: state.user.user
// })
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(User);