import { devLog } from '../../_helper/commonMethods';
import { OPEN_TOKEN } from '../../Const';
import {
  getSignature,
  generateRequiredFormDataFields,
  generateFetchRequest,
} from '../../_helper/apiMethods';
import store from '../../_helper/helper.store';

var ip = require('ip');
export const List = ({ filter, MOUAgreed, refreshCache = false }) => {
  const formData = new FormData();
  let {
    user: { password },
  } = store.getState();
  let signature = ip.address().toLowerCase() + OPEN_TOKEN.toLowerCase();
  devLog('raw sig', signature);
  signature = getSignature(signature);

  formData.append('universityUID', password.universityUID);
  formData.append('refreshCache', refreshCache);
  // formData.append("Publish", published);

  formData.append('Signature', signature);
  formData.append('MOUAgreed', MOUAgreed);
  formData.append('City', filter.city);
  formData.append('ProgramName', filter.programName);
  formData.append('FreeText', filter.freeText);
  formData.append('OpenToken', OPEN_TOKEN);
  // formData.append("ActionBy", password.userUID);
  formData.append('DeviceIP', ip.address());

  let url = 'v2/campus/list';
  return generateFetchRequest(formData, url, '');
};

export const ListByPage = (user, formData) => {
  let signature = ip.address() + user.userUID.toLowerCase() + user.sessionToken;
  devLog('raw sig', signature);
  signature = getSignature(signature);
  formData.append('Signature', signature);
  formData.append('ActionBy', user.userUID);
  formData.append('DeviceIP', ip.address());

  let url = '/campus/listbypage';
  return generateFetchRequest(formData, url);
};

export const Detail = (actionby, campusUID) => {
  const formData = new FormData();
  let signature = campusUID.toString().toLowerCase() + OPEN_TOKEN.toLowerCase();
  devLog('raw sig', signature);

  signature = getSignature(signature);
  formData.append('campusUID', campusUID);
  formData.append('Signature', signature);
  formData.append('OpenToken', OPEN_TOKEN);
  formData.append('ActionBy', actionby ? '' : actionby);
  formData.append('DeviceIP', ip.address());

  let url = '/campus/detail';
  return generateFetchRequest(formData, url);
};

export const Add = (formData) => {
  let {
    user: {
      password: { universityUID },
    },
  } = store.getState();

  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: universityUID,
  });
  formData.append('universityUID', universityUID);
  let url = 'v2/campus/add';
  return generateFetchRequest(formData, url, '');
};

export const Update = (formData, campusUID) => {
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: campusUID,
  });

  formData.append('uid', campusUID);

  let url = 'v2/campus/update';
  return generateFetchRequest(formData, url, '');
};

export const Publish = (publish, campusUID) => {
  let formData = generateRequiredFormDataFields({ signatureKey: campusUID });
  formData.append('publish', publish);
  formData.append('campusUID', campusUID);

  let url = '/campus/publish';
  return generateFetchRequest(formData, url);
};
