import React from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import {
  BarChart as BarChartIcon,
  Dashboard as DashIcon,
  People as PeopleIcon,
  Book as BookIcon,
  // Settings as SettingsIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import {
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItem,
} from '@material-ui/core';

import { NAV_STRINGS, USER_ROLE } from '../Const';
import store from '../_helper/helper.store';

const styles = (theme) => ({
  menuItem: {
    marginTop: 10,
    backgroundColor: '#4527A0',
    color: theme.palette.common.white,
    borderLeft: '7px solid transparent',
  },
  textName: {
    color: theme.palette.common.white,
    fontSize: 15,
  },
  nestedMenuItem: {
    padding: '2px 10px 2px 30px',
    color: 'white',
    fontSize: 15,
  },
  nestedSelected: {
    color: '#B388FF',
  },
  selected: {
    marginTop: 10,
    borderLeft: '7px solid #B388FF',
    backgroundColor: '#4527A0',
    color: theme.palette.common.white,
  },
});

let MenuInstance = null;
class MenuItems extends React.PureComponent {
  static changeMenu(menuID, subMenuID, nested, state) {
    MenuInstance.changeRoute(menuID, subMenuID, nested, state);
  }

  checkRoleAndFilterMenu = () => {
    let {
      user: {
        password: { userRole },
      },
    } = store.getState();
    let menu = this.getMenus();

    let {
      dashboard,
      universityCMS,
      studentList,
      users,
      reports,
      externalAccess,
    } = NAV_STRINGS;
    if (USER_ROLE.admin.test(userRole)) {
      return menu;
    }

    if (USER_ROLE.manager.test(userRole)) {
      let menuList = [
        dashboard,
        universityCMS,
        studentList,
        reports,
        externalAccess,
      ];
      return this.getFilteredMenus(menu, menuList);
    }

    if (USER_ROLE.dataEntry.test(userRole)) {
      let menuList = [
        dashboard,
        universityCMS,
        studentList,
        users,
        reports,
        externalAccess,
      ];
      return this.getFilteredMenus(menu, menuList);
    }

    return [];
  };

  getFilteredMenus = (menu, menuList) => {
    let filteredMenu = [];
    menuList.map((listItem) => {
      let index = menu.findIndex((menuItem) => menuItem.name === listItem);
      if (index > -1) {
        filteredMenu.push(menu[index]);
      }
      return null;
    });
    return filteredMenu;
  };

  getMenus = () => [
    {
      title: 'Dashboard',
      name: NAV_STRINGS.dashboard,
      icon: <DashIcon htmlColor="white" fontSize="small" />,
    },
    {
      title: 'University CMS',
      name: NAV_STRINGS.universityCMS,
      icon: <BarChartIcon htmlColor="white" fontSize="small" />,
    },
    {
      title: 'Student List',
      name: NAV_STRINGS.studentList,
      icon: <PeopleIcon htmlColor="white" fontSize="small" />,
    },
    {
      title: 'User',
      name: NAV_STRINGS.users,
      icon: <PersonIcon htmlColor="white" fontSize="small" />,
    },
    {
      title: 'Reports',
      name: NAV_STRINGS.reports,
      icon: <BookIcon htmlColor="white" fontSize="small" />,
      expand: false,
      subMenu: [
        { title: 'Campus', name: 'reports/campuswise' },
        { title: 'Program', name: 'reports/programwise' },
      ],
    },
    // {
    //   title: "External Access",
    //   name: NAV_STRINGS.externalAccess,
    //   icon: <SettingsIcon htmlColor="white" fontSize="small" />
    // }
  ];

  constructor(props) {
    super(props);
    MenuInstance = this;
    this.state = {
      menus: this.checkRoleAndFilterMenu(),
      selectedID: null,
      expandID: NAV_STRINGS.dashboard,
    };
  }

  changeRoute = (mainID, subID, nested, state) => {
    let routeName = null;
    if (nested) {
      routeName = subID;
      this.setState({
        expandID: mainID,
        selectedID: subID,
      });
    } else {
      routeName = mainID;
      this.setState({
        expandID: mainID,
        selectedID: null,
      });
    }
    this.props.history.push(`/${'university'}/${routeName}`, state);
  };

  expandMenu = (name) => {
    this.setState({ expandID: name });
  };

  render() {
    const { classes } = this.props;
    let { selectedID, expandID, menus } = this.state;

    return (
      <div>
        <MenuList style={{ padding: 0 }}>
          {menus.map((item, id) => 
              item.subMenu ? (
                <div key={id}>
                  <ListItem
                    button
                    className={
                      expandID === item.name
                        ? classes.selected
                        : classes.menuItem
                    }
                    onClick={(e) => this.expandMenu(item.name)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      classes={{ primary: classes.textName }}
                    />
                    {expandID === item.name ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {/* <MenuItem
                    onClick={e => this.expandMenu(item.name)}
                    className={
                      expandID === item.name
                        ? classes.selected
                        : classes.menuItem
                    }
                  >
                    {item.icon}
                    <ListItemText
                      primary={item.title}
                      classes={{ primary: classes.textName }}
                    />
                    <ListItemIcon classes={{ root: classes.textName }}>
                      {expandID === item.name ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                  </MenuItem> */}
                  <Collapse in={item.name === expandID}>
                    {item.subMenu.map((subitem) => (
                      <MenuItem
                        key={subitem.name}
                        onClick={() =>
                          this.changeRoute(item.name, subitem.name, true)
                        }
                        dense
                        classes={{
                          root: classes.nestedMenuItem,
                          selected: classes.nestedSelected,
                        }}
                        selected={subitem.name === selectedID}
                      >
                        {subitem.title}
                      </MenuItem>
                    ))}
                  </Collapse>
                </div>
              ) : (
                <ListItem
                  button
                  key={item.name}
                  onClick={() => this.changeRoute(item.name)}
                  className={
                    expandID === item.name ? classes.selected : classes.menuItem
                  }
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    classes={{ primary: classes.textName }}
                  />
                </ListItem>
              )
          )}
        </MenuList>
      </div>
    );
  }
}
const StyledComponent = withStyles(styles)(MenuItems);
const Menu = withRouter(StyledComponent);
export default Menu;
