import { MASTER_NAMES } from "../Const";
import {
  generateRequiredFormDataFields,
  generateFetchRequest
} from "../_helper/apiMethods";

export const List = () => {
  let listName = MASTER_NAMES;
  let formData = generateRequiredFormDataFields({ signatureKey: listName });
  formData.append("IsActive", true);
  formData.append("ListName", listName);

  let url = "v3/master/list";
  return generateFetchRequest(formData, url, '');
};

export const UploadBlobPublic = file => {
  let formData = generateRequiredFormDataFields();

  formData.append("File", file, file.name);
  formData.append("AccessType", "public");
  let url = "/master/uploadtoblob";
  return generateFetchRequest(formData, url);
};

export const AccessBlob = bloburl => {
  let formData = generateRequiredFormDataFields({ signatureKey: bloburl });
  formData.append("bloburl", bloburl);

  let url = "/master/accessbloburl";
  return generateFetchRequest(formData, url);
};
