import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Dialog,
  Button,
  AppBar,
  Slide,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Grid,
} from '@material-ui/core';
import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import moment from 'moment';
import { connect } from 'react-redux';
import { t } from '../../_language';

import {
  AdvancedSearch,
  CustomTableHead,
  TablePaginationActions,
  RenderColumnData,
} from '../../component/tableComponents';

import StudentDetails from './StudentDetails';
import { CampusAPI, ReportAPI } from '../../_api';
import { PageTitle, LoadingIndicator, PageBody } from '../../component';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" {...props} ref={ref} />;
});

const PAGE_STRING = 'studentList.';

class StdudentList extends React.Component {
  componentDidMount() {
    this.getStudentList();
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedRecord: null,
      translateKey: 'studentList',
      loading: false,
      studentList: [],
      advanceSearch: false,
      page: 0,
      rowsPerPage: 10,
      totalCount: 0,
      sortData: 'firstName asc',
      filterData: this.getFilterData(),
      columnData: [
        { id: 'firstName', label: t(PAGE_STRING + 'name'), sort: 'asc' },
        { id: 'phoneNo', label: t(PAGE_STRING + 'phone'), sort: false },
        { id: 'email', label: t(PAGE_STRING + 'email'), sort: false },
        { id: 'campusName', label: t(PAGE_STRING + 'campus'), sort: false },
        { id: 'programName', label: t(PAGE_STRING + 'program'), sort: false },
      ],
    };
  }

  getFilterData = () => [
    {
      value: moment().subtract(1, 'month'),
      label: t(PAGE_STRING + 'startDate'),
      name: 'CreatedAt',
      type: 'date',
      operator: '>=',
    },
    {
      value: moment(),
      label: t(PAGE_STRING + 'endDate'),
      name: 'CreatedAt',
      type: 'date',
      operator: '<=',
    },
    {
      value: '',
      label: t(PAGE_STRING + 'name'),
      name: 'firstName',
      type: 'text',
      operator: '=',
    },
    {
      value: '',
      label: t(PAGE_STRING + 'campusName'),
      name: 'campusName',
      type: 'text',
      operator: '=',
    },
    {
      value: '',
      label: t(PAGE_STRING + 'programName'),
      name: 'programName',
      type: 'text',
      operator: '=',
    },
    {
      value: '',
      label: t(PAGE_STRING + 'email'),
      name: 'email',
      type: 'text',
      operator: '=',
    },
    {
      value: '',
      label: t(PAGE_STRING + 'phone'),
      name: 'phoneNo',
      type: 'text',
      operator: '=',
    },
  ];

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.getStudentList());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value }, () =>
      this.getStudentList(),
    );
  };

  onAdvanceSearch = ({ columnData, filterData }) => {
    this.setState(
      {
        page: 0,
        advanceSearch: false,
        columnData,
        filterData,
      },
      () => this.getStudentList(),
    );
  };

  getFilterDataWithFormat = () => {
    let { filterData } = this.state;
    let modFilData = [];

    filterData.map((item) => {
      if (item.value) {
        modFilData.push({
          field: item.name,
          value:
            item.type === 'date' ? item.value.format('YYYY-MM-DD') : item.value,
          operator: item.operator,
        });
      }
      return null;
    });
    return modFilData;
  };

  getStudentList = async (isExport) => {
    LoadingIndicator.instance.showLoader();

    let { sortData, page, rowsPerPage, totalCount } = this.state;
    let formData = new FormData();
    formData.append('Sorting', sortData);
    formData.append('Filter', JSON.stringify(this.getFilterDataWithFormat()));
    if (isExport) {
      formData.append('PageNo', 1);
      formData.append('PageSize', totalCount);
    } else {
      formData.append('PageNo', page + 1);
      formData.append('PageSize', rowsPerPage);
    }

    let response = await CampusAPI.Student.List(formData);
    if (isExport) {
      return response;
    } else if (response) {
      this.setState({
        studentList: response.list,
        totalCount: response.totalRecordsCount[0].totalRecordsCount,
      });
    }
    

    LoadingIndicator.instance.hideLoader();
  };

  handleRequestSort = (columnData) => {
    var sortData = '';
    columnData.map((col, index) => {
      if (col.sort !== false) {
        if (index === 0) {
          sortData = sortData.concat(`${col.id}${' '}${col.sort}`);
        } else {
          sortData = sortData.concat(`${' '}${col.id}${' '}${col.sort}`);
        }
      }
      return null;
    });
    this.setState(
      {
        page: 0,
        columnData,
        sortData,
      },
      () => {
        this.getStudentList();
      },
    );
  };

  onViewDetails = async (item, id) => {
    LoadingIndicator.instance.showLoader();
    let response = await CampusAPI.Student.Detail(item.uid);

    if (response) {
      this.setState({
        selectedRecord: {
          ...response,
          attach: response.attach.map((d) => ({
            ...d,
            createdAt: moment(d.createdAt).format('DD MMM YYYY hh:mm A'),
          })),
        },
      });
    }
    LoadingIndicator.instance.hideLoader();
  };

  onCloseDetails = () => {
    this.setState({
      selectedRecord: null,
      selectedRecordID: null,
    });
  };

  toggleAdvanceSerach = () => {
    this.setState((state) => ({
      advanceSearch: !state.advanceSearch,
    }));
  };

  getExportHeaders = () => {
    return {
      firstName: 'Name',
      phoneNo: 'Mobile',
      email: 'Email',
      programName: 'Program',
      campusName: 'Campus',
      status: 'Status',
    };
  };

  generateExportAllData = (list) => {
    var itemsFormatted = [];
    let headers = this.getExportHeaders();
    list.forEach((item) => {
      let formatedItem = {
        firstName: item.name,
        phoneNo: item.phoneNo,
        email: item.email,
        programName: item.programName,
        campusName: item.campusName,
        status: item.status,
      };
      itemsFormatted.push(formatedItem);
    });
    return { itemsFormatted, headers };
  };
  generateExportData = (list) => {
    var itemsFormatted = [];
    let headers = this.getExportHeaders();
    list.forEach((item) => {
      let formatedItem = {
        firstName: item.firstName,
        phoneNo: item.phoneNo,
        email: item.email,
        programName: item.programName,
        campusName: item.campusName,
        status: item.status,
      };
      itemsFormatted.push(formatedItem);
    });
    return { itemsFormatted, headers };
  };

  onExport = async () => {
    let response = await this.getStudentList(true);
    if(response)
    {
      // format the data
      let { itemsFormatted, headers } = this.generateExportData(response.list);
      this.download(headers, itemsFormatted, 'StudentsList');
    }
    LoadingIndicator.instance.hideLoader();
  };

  onExportAll = async () => {
    LoadingIndicator.instance.showLoader();
    let { filterData } = this.state;
    let {
      user: {
        password: { universityUID },
      },
    } = this.props;
    let formData = new FormData();
    let maxDate = new Date(Date.now() + (3600 * 1000 * 24 * 30)).toISOString().split('T')[0];
    let minDate = '1970-01-01';
    formData.append('startDate',minDate);
    formData.append('EndDate', maxDate);
    formData.append('universityUID', universityUID);

    let response = await ReportAPI.ExportAll(formData);
    if (response) {
      let { itemsFormatted, headers } = this.generateExportAllData(response);
      this.download(headers, itemsFormatted, 'StudentsListAll');
    }
    LoadingIndicator.instance.hideLoader();
  };

  convertToCSV = (objArray) => {
    var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  };

  exportCSVFile = (headers, items, fileTitle) => {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = this.convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    this.setState({ loading: false });
  };

  download = (headers, itemsFormatted, fileTitle) => {
    this.exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  };

  renderListItem = (item, id) => {
    return (
      <TableRow key={item.uid}>
        {this.state.columnData.map(RenderColumnData(item))}

        <TableCell>
          <Button
            size="small"
            onClick={() => this.onViewDetails(item, id)}
            variant="text"
          >
            {t('buttons.viewDetails')}
          </Button>
        </TableCell>
      </TableRow>
    );
  };
  render() {
    const {
      advanceSearch,
      filterData,
      columnData,
      selectedRecord,
      totalCount,
      studentList,
      rowsPerPage,
      page,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, studentList.length);

    return (
      <React.Fragment>
        <PageTitle
          title={t(PAGE_STRING + 'title')}
          iconData={[
            {
              icon: <FilterListIcon onClick={this.toggleAdvanceSerach} />,
            },
          ]}
        />
        <PageBody>
          <AdvancedSearch
            open={advanceSearch}
            toggleAdvanceSerach={this.toggleAdvanceSerach}
            data={{
              columnData,
              filterData,
            }}
            onAdvanceSearch={this.onAdvanceSearch}
          />

          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={this.onExport}>{t('buttons.export')}</Button>
            </Grid>
            <Grid item>
              <Button onClick={this.onExportAll}>
                {t('buttons.exportAll')}
              </Button>
            </Grid>
          </Grid>

          <Table>
            <CustomTableHead
              columnData={columnData}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {studentList.map(this.renderListItem)}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={12}
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  ActionsComponent={TablePaginationActions}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </PageBody>
        {selectedRecord && (
          <React.Fragment>
            <StudentDetailsDialog
              open={selectedRecord ? true : false}
              onClose={this.onCloseDetails}
            >
              <AppBar position="static">
                <Toolbar>
                  <IconButton
                    color="inherit"
                    onClick={this.onCloseDetails}
                    aria-label="Close"
                    tabIndex="1"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="subtitle2" color="inherit">
                    {t(PAGE_STRING + 'studentDetail')}
                  </Typography>
                </Toolbar>
              </AppBar>

              <StudentDetails
                attachments={selectedRecord.attach}
                detail={selectedRecord.detail}
              />
            </StudentDetailsDialog>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const useStyles = makeStyles((theme) => ({
  paper: {
    height: '80%',
  },
}));

const StudentDetailsDialog = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      scroll="paper"
      maxWidth="md"
      classes={{
        paper: classes.paper,
      }}
      TransitionComponent={Transition}
      {...props}
    />
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(StdudentList);
