import {
  generateRequiredFormDataFields,
  generateFetchRequest,
} from '../../_helper/apiMethods';
import store from '../../_helper/helper.store';

export const List = (formData) => {
  let {
    user: {
      password: { universityUID },
    },
  } = store.getState();
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: universityUID,
  });
  formData.append('universityUID', universityUID);

  let url = 'v2/campus/student/listbypage';

  return generateFetchRequest(formData, url, '');
};

export const Detail = (uid) => {
  let formData = generateRequiredFormDataFields({
    signatureKey: uid,
  });
  formData.append('uid', uid);

  let url = 'v2/campus/student/detail';

  return generateFetchRequest(formData, url, '');
};

export const StatusChange = (formData) => {
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: formData.get('uid'),
  });

  let url = 'v2/campus/student/Status/Change';
  return generateFetchRequest(formData, url, '');
};

export const StatusTracking = (StudentAPFormUID) => {
  let formData = generateRequiredFormDataFields({
    signatureKey: StudentAPFormUID,
  });
  formData.append('StudentAPFormUID', StudentAPFormUID);
  let url = 'v2/campus/student/status/Tracking';
  return generateFetchRequest(formData, url, '');
};
