export const LOGIN_SUCCESS = "user/login";
export const LOGOUT = "user/logout";
export const MASTER_DATA = "user/master";
export const UNIVERSITY = "user/university";
export const TEMPJSON = "user/tempjson";
export const SET_LANGUAGE = "user/setlang";

const initialState = {
  from: "",
  email: "",
  password: {
    userUID: ""
  },
  isLoggedIn: false,
  defaultlanguage: "id"
};
export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.user,
        isLoggedIn: true
      };
    case LOGOUT:
      return initialState;
    case SET_LANGUAGE:
      return {
        ...state,
        defaultlanguage: action.value
      };
    default:
      return state;
  }
};
export const storeUser = res => {
  return dispatch => {
    dispatch({
      type: LOGIN_SUCCESS,
      user: res
    });
  };
};
export const removeUser = () => {
  sessionStorage.removeItem("goKampus");
  window.location.href = "/login";
  return dispatch => {
    dispatch({
      type: LOGOUT
    });
  };
};

export const setLanguageToRedux = value => {
  return dispatch => {
    dispatch({
      type: SET_LANGUAGE,
      value
    });
  };
};
