import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Checkbox,
  ListItemText,
  TextField,
  Button,
  DialogActions,
  Typography,
  ListItem,
  ListItemIcon
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { t } from "../../_language";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
const advanceSearchStyle = theme => ({
  root: {
    padding: 0
  },
  typoRoot: {
    marginLeft: 15
  },
  muiGridSpace: {
    padding: 0
  },
  titleRoot: {
    marginLeft: 16
  },
  listItem: {
    fontSize: 12
  }
});
class AdvancedSearch extends React.Component {
  componentDidMount() {
    let { columnData, filterData } = this.props.data;
    let state = {
      modColumnData: [...columnData],
      filterData: [...filterData]
    };
    this.setState(state);
  }

  state = {
    filterData: [],
    modColumnData: []
  };

  handleChange = (event, index, name) => {
    let { filterData } = this.state;
    var newData = filterData.slice();
    var oldData = newData[index];
    var newRecord = Object.assign({}, oldData);

    if (name) {
      newRecord.value = event;
    } else {
      let { value } = event.target;
      newRecord.value = value;
    }

    newData[index] = newRecord;

    this.setState({
      filterData: newData
    });
  };

  handleColumnDataChange = id => {
    let { modColumnData } = this.state;
    var newData = modColumnData.slice();
    var oldData = newData[id];
    var newRecord = Object.assign({}, oldData);
    newRecord.visibility = newRecord.visibility !== false ? false : true;
    newData[id] = newRecord;
    this.setState({
      modColumnData: newData
    });
  };

  cancelAdvanceSearch = () => {
    this.setState({
      filterData: this.props.data.filterData,
      modColumnData: this.props.data.columnData
    });
    this.props.toggleAdvanceSerach();
  };

  finish = () => {
    let { modColumnData, filterData } = this.state;
    this.props.onAdvanceSearch({ columnData: modColumnData, filterData });
  };

  clearFilter = () => {
    let { filterData } = this.state;
    this.setState({
      filterData: filterData.map(item => {
        if (item.type === "date" && item.label === "Start Date") {
          item.value = moment().subtract(1, "month");
        } else if (item.type === "date" && item.label === "End Date") {
          item.value = moment();
        } else {
          item.value = "";
        }
        return item;
      })
    });
  };

  render() {
    let { classes, open } = this.props;
    let { filterData, modColumnData } = this.state;
    return (
      <Dialog
        open={open}
        maxWidth="md"
        onClose={this.props.toggleAdvanceSerach}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DialogContent>
            <Typography
              variant="subtitle2"
              classes={{
                root: classes.titleRoot
              }}
            >
              {t("text.commonText.advanceSearch")}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography
                  variant="subtitle2"
                  classes={{
                    root: classes.typoRoot
                  }}
                >
                  {t("text.commonText.columnSelector")}
                </Typography>
                <Grid item xs={12} style={{ height: 15 }}>
                  {" "}
                </Grid>
                {modColumnData.map((item, id) => (
                  <ListItem
                    classes={{
                      root: classes.root
                    }}
                    onClick={() => this.handleColumnDataChange(id)}
                    key={item.id}
                    value={item.label}
                    dense
                    button
                    divider
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={item.visibility !== false}
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.listItem
                      }}
                      primary={item.label}
                    />
                  </ListItem>
                ))}
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2">
                  {t("text.commonText.filter")}
                </Typography>

                <Grid
                  container
                  spacing={3}
                  classes={{
                    container: classes.muiGridSpace
                  }}
                >
                  {filterData.map((input, index) => (
                    <Grid item xs={6} key={index}>
                      {input.type === "date" ? (
                        <DatePicker
                          fullWidth
                          value={input.value}
                          label={input.label}
                          onChange={e =>
                            this.handleChange(e, index, input.name)
                          }
                        />
                      ) : (
                        <TextField
                          fullWidth
                          label={input.label}
                          name={input.name}
                          value={input.value}
                          onChange={ev => this.handleChange(ev, index)}
                        />
                      )}
                    </Grid>
                  ))}
                  <Grid item xs={6} />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </MuiPickersUtilsProvider>
        <DialogActions>
          <Button onClick={this.finish}>{t("buttons.finish")}</Button>
          <Button onClick={this.clearFilter}>{t("buttons.clear")}</Button>
          <Button onClick={this.cancelAdvanceSearch} variant="text">
            {t("buttons.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
const AdvancedSearchWithStyle = withStyles(advanceSearchStyle)(AdvancedSearch);

export default AdvancedSearchWithStyle;
