import store from "../_helper/helper.store";

import { getSignature, generateFetchRequest } from "../_helper/apiMethods";
import { devLog } from "../_helper/commonMethods";
import { USER_TYPE, OPEN_TOKEN } from "../Const";
var ip = require("ip");

export const Email = (email, userType) => {
  let formData = new FormData();
  formData.append("userType", USER_TYPE);
  formData.append("userID", email);
  formData.append("userType", userType);
  formData.append("DeviceIP", ip.address());

  let url = "/login/email";
  return generateFetchRequest(formData, url);
};

export const Password = (userUID, userID, userPassword, token) => {
  let signature =
    userID.toLowerCase() + userPassword.toLowerCase() + token.toLowerCase();
  devLog("raw sig", signature);

  signature = getSignature(signature);
  let formData = new FormData();
  formData.append("userID", userID);
  formData.append("userUID", userUID);
  formData.append("userPassword", userPassword);
  formData.append("token", token);
  formData.append("Signature", signature);
  formData.append("userType", USER_TYPE);
  formData.append("DeviceIP", ip.address());

  let url = "/login/password";
  return generateFetchRequest(formData, url);
};

export const ChangePassword = formData => {
  let {
    password: { userUID, sessionToken }
  } = store.getState().user;
  let signature = userUID.toLowerCase() + sessionToken;
  devLog("raw sig", signature);

  signature = getSignature(signature);
  formData.append("Signature", signature);
  formData.append("ActionBy", userUID);
  formData.append("userUID", userUID);
  formData.append("userType", USER_TYPE);
  formData.append("DeviceIP", ip.address());

  let url = "/login/change";
  return generateFetchRequest(formData, url);
};

export const ForgetPassword = (userUID, email, token) => {
  let signature = email.toLowerCase() + token;
  devLog("raw sig", signature);

  signature = getSignature(signature);
  let formData = new FormData();
  formData.append("Signature", signature);
  formData.append("userType", USER_TYPE);
  formData.append("userUID", userUID);
  formData.append("email", email);
  formData.append("ActionBy", userUID);
  formData.append("DeviceIP", ip.address());

  let url = "/login/forget";
  return generateFetchRequest(formData, url);
};

export const UniversitySignup = formData => {
  let signature =
    formData.get("universityName").toLowerCase() + OPEN_TOKEN.toLowerCase();

  devLog("raw sig", signature);

  signature = getSignature(signature);
  formData.append("Signature", signature);
  formData.append("DeviceIP", ip.address());

  let url = "/university/signup";
  return generateFetchRequest(formData, url);
};

export const DataEntryLogin = DEToken => {
  let signature = DEToken.toLowerCase() + OPEN_TOKEN.toLowerCase();

  devLog("raw sig", signature);
  signature = getSignature(signature);
  let formData = new FormData();
  formData.append("DEToken", DEToken);
  formData.append("Signature", signature);
  formData.append("DeviceIP", ip.address());

  let url = "/login/TokenSignin";
  return generateFetchRequest(formData, url);
};
