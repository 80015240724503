import React, { Component } from 'react';
import { Grid, Button, Typography, Paper } from '@material-ui/core';

import { LoginAPI } from '../../_api';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import LoadingIndicator from '../../component/Loader';

import { TRANS_STRING, NAV_STRINGS } from '../../Const';

import { Footer, Header } from '../../component';
import { t } from '../../_language';
const PAGE_STRING = 'loginSignup.';
const { COMMON_STRING, INPUT_REQ, INPUT_INVALID } = TRANS_STRING;

const theme = createTheme({
  palette: {
    primary: {
      main: '#5E35B1',
      fontFamily: 'Montserrat',
    },
    secondary: {
      main: '#F68E58',
      contrastText: 'white',
      fontFamily: 'Montserrat',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        fontFamily: 'Montserrat',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Montserrat',
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'Montserrat',
      },
    },
  },
});
ValidatorForm.addValidationRule('noHtml', (value) => {
  let htmlRegex = /<(.|\n)*?>/;
  if (value && htmlRegex.test(value)) {
    return false;
  }
  return true;
});
class Signup extends Component {
  onPressUniversitySignup = async (ev) => {
    await LoadingIndicator.instance.showLoader();
    let formData = new FormData(ev.target);
    let response = await LoginAPI.UniversitySignup(formData);
    if (response) {
      this.setState({
        signUpSuccess: true,
      });
      window.fb_report_conversion();
      window.gtag_report_conversion();
    }
    LoadingIndicator.instance.hideLoader();
  };

  onChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      signupModel: {
        ...this.state.signupModel,
        [name]: value,
      },
    });
  };

  state = {
    signUpSuccess: false,
    signupModel: {
      universityName: '',
      phone: '',
      email: '',
      notes: '',
      address: '',
    },
  };

  onClickSignin = () => {
    this.props.history.push(NAV_STRINGS.login);
  };
  onClickLogo = () => {
    this.props.history.push('/');
  };
  render() {
    let { signupModel, signUpSuccess } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Header />
        {signUpSuccess ? (
          <Grid
            container
            spacing={10}
            style={{ marginTop: '65px', marginBottom: '100px' }}
            className="thankyou"
          >
            <Grid item md={12}>
              <Typography align="center">
                <h1>{t(PAGE_STRING + 'signupSuccess.thanks')}</h1>
                <h3>
                  {t(PAGE_STRING + 'signupSuccess.text1')}{' '}
                  <strong>goKampus!</strong>
                  <br />
                  {t(PAGE_STRING + 'signupSuccess.text2')}
                </h3>
              </Typography>
            </Grid>
            <Grid container>
              <Grid item md={6} className="first">
                <div className="box-border padding20 text-left">
                  <div className="vertical-align">
                    <img
                      src={'/assets/images/dimas.png'}
                      alt="testimoni "
                      className="imgrounded img-responsive"
                    />
                    <p>
                      <strong>Dimas Andhika Pratama</strong>
                      <br />
                      <span className="textorange">
                        Head of Marketing and Admission Office UPH Surabaya{' '}
                      </span>
                    </p>
                  </div>
                  <p>{t(PAGE_STRING + 'signupSuccess.testi1')}</p>
                </div>
              </Grid>
              <Grid item md={6} className="last">
                <div className="box-border padding20 text-left">
                  <div className="vertical-align">
                    <img
                      src={'/assets/images/jaya.png'}
                      alt="testimoni "
                      className="imgrounded img-responsive"
                    />
                    <p>
                      <strong>Jaya Suteja Ph.D</strong>
                      <br />
                      <span className="textorange">
                        Perwakilan Penerimaan Mahasiswa Baru di Universitas
                        Surabaya (UBAYA){' '}
                      </span>
                    </p>
                  </div>
                  <p>{t(PAGE_STRING + 'signupSuccess.testi2')}</p>
                </div>
              </Grid>
            </Grid>
            <Grid item md={12} style={{ paddingTop: 0, width: '100%' }}>
              <Typography align="center">
                <h1>{t(PAGE_STRING + 'signupSuccess.follow')}</h1>
                <h3>{t(PAGE_STRING + 'signupSuccess.follow2')}</h3>
                <div className="img-socmed">
                  <ul>
                    <li>
                      <a
                        href="https://www.instagram.com/gokampusofficial/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="center-block"
                          src={'/assets/images/instagram-icon.png'}
                          alt="goKampus Instagram"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/goKampus_"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="center-block"
                          src={'/assets/images/twitter-icon.png'}
                          alt="goKampus Twitter"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCJ2GeQy5ogNk7_3YL6RYWag"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="center-block"
                          src={'/assets/images/youtube-icon.png'}
                          alt="goKampus YouTube"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://line.me/R/ti/p/%40ouu9013m"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="center-block"
                          src={'/assets/images/line-icon.png'}
                          alt="goKampus Line"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/Gokampusofficial-944187515760171/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="center-block"
                          src={'/assets/images/facebook-icon.png'}
                          alt="goKampus Facebook"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={3}
            style={{ marginTop: '65px', marginBottom: '100px' }}
          >
            <Grid item md={4}></Grid>
            <Grid item md={4} ThemeProvider={4}>
              <Paper elevation={4} style={{ padding: '25px' }}>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} className="text-center">
                    <img
                      src={'/assets/images/logo.png'}
                      style={{ width: 248 }}
                      alt="goKampus"
                      className={'logoImg'}
                    />
                    <Typography
                      variant="h5"
                      color="primary"
                      style={{
                        margin: '10px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      {t(PAGE_STRING + 'signupTitle')}
                    </Typography>
                  </Grid>
                </Grid>
                <React.Fragment>
                  <ValidatorForm onSubmit={this.onPressUniversitySignup}>
                    <TextValidator
                      onChange={this.onChange}
                      value={signupModel.universityName}
                      name="universityName"
                      label={t(COMMON_STRING + 'universityName')}
                      validators={['required', 'noHtml']}
                      errorMessages={[t(INPUT_REQ), t(INPUT_INVALID)]}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />

                    <TextValidator
                      onChange={this.onChange}
                      value={signupModel.email}
                      name="email"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label={t(COMMON_STRING + 'email')}
                      validators={['required', 'isEmail']}
                      errorMessages={[t(INPUT_REQ), t(INPUT_INVALID)]}
                    />

                    <TextValidator
                      onChange={this.onChange}
                      type="number"
                      name="phone"
                      label={t(COMMON_STRING + 'phone')}
                      value={signupModel.phone}
                      validators={['required', 'noHtml']}
                      errorMessages={[t(INPUT_REQ), t(INPUT_INVALID)]}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />

                    <TextValidator
                      multiline
                      maxRows="4"
                      value={signupModel.notes}
                      onChange={this.onChange}
                      name="notes"
                      label={t(COMMON_STRING + 'notes')}
                      validators={['noHtml']}
                      errorMessages={[t(INPUT_INVALID)]}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />

                    <TextValidator
                      multiline
                      maxRows="4"
                      value={signupModel.address}
                      onChange={this.onChange}
                      name="address"
                      label={t(COMMON_STRING + 'address')}
                      validators={['noHtml']}
                      errorMessages={[t(INPUT_INVALID)]}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '24px',
                            fontWeight: '700',
                            marginTop: '15px',
                          }}
                        >
                          {t('buttons.signup')}
                        </Button>
                      </Grid>
                      <Grid item xs={12} container justifyContent="center">
                        <Button
                          color="primary"
                          onClick={this.onClickSignin}
                          style={{ fontFamily: 'Montserrat' }}
                        >
                          {t('buttons.alreadyAccount')}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </React.Fragment>
              </Paper>
              <br />
              <Typography
                color="primary"
                variant="subtitle2"
                align="center"
                style={{
                  margin: '10px',
                  fontFamily: 'Montserrat',
                  textAlign: 'center',
                  paddingTop: '20px',
                  fontSize: '16px',
                }}
              >
                {t(PAGE_STRING + 'termsAndAgree')}
              </Typography>
            </Grid>
            <Grid item md={4}></Grid>
          </Grid>
        )}
        <Footer />
      </ThemeProvider>
    );
  }
}

export default Signup;
