import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import {
  getSelectDropdownOptions,
  getSelectDropdownOptionsWithID,
} from '../../_helper/commonMethods';
import Select from 'react-select';
import { defaultFontSize } from '../../styles/Theme';
class ReactSelectValidator extends ValidatorComponent {
  onChange = (value) => {
    let { withID, onChange, name, isMulti } = this.props;
    let returnVal;
    if (isMulti) {
      returnVal = value;
    } else returnVal = withID ? value : value.value;
    onChange({
      target: {
        name: name,
        value: returnVal,
        type: 'select',
        id: value.id
      },
    });
  };
  renderValidatorComponent() {
    const {
      label,
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      value,
      data,
      id,
      withID,
      disabled,
      noMapping,
      onChange,
      ...rest
    } = this.props;

    let list = [];
    let selectedValue;

    if (withID) {
      list = data.map(getSelectDropdownOptionsWithID(withID));
      selectedValue = value;
    } else {
      list = data.map(getSelectDropdownOptions);
      if (rest.isMulti) {
        selectedValue = value;
      } else selectedValue = value ? { value: value, label: value, id:id } : null;
    }

    return (
      <FormControl
        fullWidth
        error={!this.state.isValid}
        disabled={disabled}
        style={{ zIndex: 'Auto' }}
      >
        <FormLabel>{selectedValue ? label : ''}</FormLabel>
        <Select
          {...rest}
          options={list}
          value={selectedValue}
          isSearchable
          isDisabled={disabled}
          onChange={this.onChange}
          styles={{
            container: (data) => ({
              ...data,
              marginTop: 5,
            }),
            option: (data) => ({
              ...data,
              fontFamily: 'Montserrat',
              fontSize: defaultFontSize,
              color: '#000',
            }),
            clearIndicator: (data) => ({
              ...data,
              padding: 0,
            }),
            control: (data) => ({
              ...data,
              border: 'none',
              borderBottom: ' 1px solid rgba(0, 0, 0, 0.42)',
              borderTop: 'none !important',
              paddingLeft: 0,
              fontFamily: 'Montserrat',
              fontSize: defaultFontSize,
              color: '#000',
              minHeight: 20,
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              borderRadius: 0,
              boxShadow: 'none',
            }),
            input: (data) => ({
              ...data,
              margin: '0px 4px',
              padding: '1px 3px',
            }),
            dropdownIndicator: (data) => ({
              ...data,
              paddingTop: 1.5,
              paddingBottom: 1.5,
            }),
            placeholder: () => ({
              color: 'rgba(0, 0, 0, 0.54)',
              fontSize: defaultFontSize,
              fontWeight: 'bold',
            }),
            multiValue: (data) => ({
              ...data,
              fontSize: defaultFontSize,
            }),
            singleValue: (data) => ({
              ...data,
              fontSize: defaultFontSize,
            }),
            valueContainer: (data) => ({
              ...data,
              paddingBottom: 5,
              paddingLeft: 0,
            }),
          }}
          placeholder={label}
        />

        {this.errorText()}
      </FormControl>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }
    return <FormHelperText>{this.getErrorMessage()}</FormHelperText>;
  }
}

export default ReactSelectValidator;
