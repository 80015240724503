import {
  generateRequiredFormDataFields,
  generateFetchRequest
} from "../../_helper/apiMethods";

var ip = require("ip");
export const List = campusUID => {
  let formData = generateRequiredFormDataFields({ signatureKey: ip.address() });
  formData.append("campusUID", campusUID);

  let url = "v3/campus/program/list";
  return generateFetchRequest(formData, url, '');
};

export const Update = formData => {
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: formData.get("campusUID")
  });

  let url = "/campus/program/update";
  return generateFetchRequest(formData, url);
};

export const Add = formData => {
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: formData.get("campusUID")
  });

  let url = "/campus/program/add";
  return generateFetchRequest(formData, url);
};

export const DisctinctProgramList = () => {
  let formData = generateRequiredFormDataFields();

  let url = "/campus/disctinctprogramname";
  return generateFetchRequest(formData, url);
};
