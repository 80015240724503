const STORE_MASTER = 'master/store';
const initialState = {
  countryList: [],
  cityList: [],
  scholarshipStatusMappingList: [],
  universityProgramList: [],
  universityProgramTypeList: [],
  provinceList: [],
  campusSchoolTypeList: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_MASTER:
      return {
        ...state,
        ...action.list,
      };
    default:
      return state;
  }
};

export const storeMaster = (res) => {
  let list = {};
  for (var key in res) {
    if (res[key].length) list[key] = res[key];
  }
  return (dispatch) => {
    dispatch({
      type: STORE_MASTER,
      list,
    });
  };
};
