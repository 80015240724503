import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./_helper/helper.store";
import { unregister } from "./registerServiceWorker";
import App from "./app";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import "./styles/custom-css.css";
import "./styles/fonts/fonts.css";
import "react-toastify/dist/ReactToastify.css";

render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById("app")
);
unregister();
