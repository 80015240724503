import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { t } from '../../_language';
import AttachmentList from './AttachmentList';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { LoadingIndicator } from '../../component';
import {
  CampusAPI, 
  // MasterAPI 
} from '../../_api';
import moment from 'moment';
import { toast } from 'react-toastify';
import { TRANS_STRING } from '../../Const';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
const PAGE_STRING = 'studentList.studentDetails.personal.';
const { SAVED_STRING, COMMON_STRING, INPUT_REQ } = TRANS_STRING;
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
  },
  boldFont: {
    fontWeight: 'bold',
  },
}));
const StudentDetails = ({ attachments, detail }) => {
  const [state, setState] = useState({
    modal: false,
    statusHistoryModal: false,
    statusList: [],
    status: '',
    reason: '',
    type: '',
  });
  const onPressStatusAction = (type) => {
    let status = '';
    if (type === 'Accept') {
      status = 'Accept';
    } else if (type === 'Decline') {
      status = 'Decline';
    }
    setState((s) => ({
      ...s,
      modal: !s.modal,
      type,
      status,
    }));
  };
  const handleChange = (e) => {
    let { value, name } = e.target;
    setState((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const saveActions = async () => {
    await LoadingIndicator.instance.showLoader();
    let { status, reason } = state;

    let formData = new FormData();
    formData.append('uid', detail.uid);
    formData.append('notes', reason);
    formData.append('status', status);
    let response = await CampusAPI.Student.StatusChange(formData);
    if (response) {
      if (response) detail.status = status;
      setState({
        ...state,
        modal: false,
        status: '',
        reason: '',
        type: '',
      });
      toast.success(t(SAVED_STRING));
    }
    LoadingIndicator.instance.hideLoader();
  };

  const getStatusHistory = async () => {
    await LoadingIndicator.instance.showLoader();
    let response = await CampusAPI.Student.StatusTracking(detail.uid);
    if (response && response.length) {
      setState({
        ...state,
        statusList: response.map((item) => {
          item.createdAt = moment(item.createdAt).format('DD MMM YYYY hh:mm A');
          return item;
        }),
        statusHistoryModal: true,
      });
    } else {
      setState({
        ...state,
        statusList: [],
      });
      toast.warn(t(PAGE_STRING + 'noStatusFound'));
    }

    LoadingIndicator.instance.hideLoader();
  };

  const closeStatusHistoryModal = () =>
    setState({
      ...state,
      statusHistoryModal: false,
    });

  const closeStatusActionModal = () =>
    setState({
      ...state,
      modal: false,
    });

  const renderStatusListItem = (item, index) => {
    return (
      <TableRow key={index}>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              style={{ fontStyle: 'italic', marginRight: 5 }}
              variant="subtitle1"
            >
              {item.status}
            </Typography>
            -
            <Typography style={{ marginLeft: 5 }} variant="caption">
              {item.createdAt}{' '}
            </Typography>
          </div>
          <Typography variant="body1">{item.reason} </Typography>
        </TableCell>
      </TableRow>
    );
  };

  const style = useStyles();

  return (
    <Box display="flex">
      <Grid container spacing={3} className={style.paper}>
        <DataSection title="name" value={detail.name} />
        <DataSection title="email" value={detail.email} />
        <DataSection title="phone" value={detail.phoneNo} />
        <DataSection title="schoolName" value={detail.schoolName} />
        <DataSection title="curEducation" value={detail.curEducation} />
        <DataSection title="joiningYear" value={detail.joiningYear} />
        <DataSection title="program" value={detail.program} />
        <DataSection title="campus" value={detail.campus} />
        <DataSection title="budget" value={detail.budget} />
        <AttachmentList list={attachments} />
        <StatusButtons
          onPressStatusAction={onPressStatusAction}
          onPressStatusHistory={getStatusHistory}
        />
        <Dialog
          open={state.modal}
          onClose={closeStatusActionModal}
          // disableBackdropClick
        >
          <DialogTitle tabIndex={0}>
            {t(PAGE_STRING + 'provideYourInfo')}
          </DialogTitle>
          <ValidatorForm onSubmit={saveActions}>
            <DialogContent tabIndex={-1}>
              <TextValidator
                label={t(COMMON_STRING + 'status')}
                margin="normal"
                value={state.status}
                disabled={state.type !== 'Other'}
                onChange={handleChange}
                name="status"
                validators={['required']}
                errorMessages={[t(INPUT_REQ)]}
              />
              <div style={{ minHeight: 80 }}>
                <TextValidator
                  label={t(COMMON_STRING + 'reason')}
                  multiline
                  maxRows="4"
                  margin="normal"
                  value={state.reason}
                  onChange={handleChange}
                  name="reason"
                  validators={['required']}
                  errorMessages={[t(INPUT_REQ)]}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                tabIndex={5}
                onClick={closeStatusActionModal}
                color="primary"
              >
                {t('buttons.cancel')}
              </Button>
              <Button tabIndex={3} type="submit" color="primary">
                {t('buttons.save')}
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
        <Dialog
          open={state.statusHistoryModal}
          onClose={closeStatusHistoryModal}
          // disableBackdropClick
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>{t(PAGE_STRING + 'statusHistory')}</DialogTitle>
          <DialogContent>
            <Table>
              <TableBody>
                {state.statusList.map(renderStatusListItem)}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeStatusHistoryModal} color="primary">
              {t('buttons.okay')}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};
const DataSection = ({ title, value }) => {
  const style = useStyles();
  return (
    <Grid item xs={6}>
      <Grid item>
        <Typography variant="subtitle2">{t(PAGE_STRING + title)}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" className={style.boldFont}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const StatusButtons = ({ onPressStatusAction, onPressStatusHistory }) => {
  return (
    <Box display="flex" padding={2}>
      <Button
        onClick={() => onPressStatusAction('Accept')}
        size="small"
        variant="contained"
      >
        {t('buttons.accept')}
      </Button>
      <Box width={2} />
      <Button
        onClick={() => onPressStatusAction('Decline')}
        size="small"
        variant="outlined"
      >
        {t('buttons.decline')}
      </Button>
      <Box width={2} />

      <Button
        onClick={() => onPressStatusAction('Other')}
        size="small"
        variant="outlined"
      >
        {t('buttons.other')}
      </Button>
      <Box width={2} />

      <Button onClick={onPressStatusHistory} size="small" variant="outlined">
        {t('buttons.showHistory')}
      </Button>
    </Box>
  );
};

export default StudentDetails;
