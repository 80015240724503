import {
  generateRequiredFormDataFields,
  generateFetchRequest,
} from '../_helper/apiMethods';

export const StudentApply = formData => {
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: formData.get('studentName'),
  });

  let url = 'sandbox/student/apply';

  return generateFetchRequest(formData, url);
};
