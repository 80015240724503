import * as routeNames from './routesPageNameMap';
import { IS_DEVEOPMENT } from '../Const';

/**
 Creates a new GUID and returns the string
 */
export const createGUID = () => {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  let GUID = (
    S4() +
    S4() +
    '-' +
    S4() +
    '-4' +
    S4().substr(0, 3) +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  ).toLowerCase();
  return GUID;
};

export const updatePageTitile = pathname => {
  let title = null;
  const getValueForRouteName = key => pathname.includes(key);
  let pageName = Object.keys(routeNames.subTitle);
  pageName = pageName.find(getValueForRouteName);
  if (pageName)
    title = `${routeNames.pageTitle} - ${routeNames.subTitle[pageName]}`;
  else title = routeNames.homePageTitle;
  document.title = title;
};

export const getSelectDropdownOptions = ({ text, id }) => ({
  value: text,
  label: text,
  id: id
});

export const getSelectDropdownOptionsWithID = idName => ({
  id,
  text,
  ...rest
}) => {
  if (typeof idName === 'string')
    return {
      value: rest[idName],
      label: text,
    };
  return { value: id, label: text };
};

export const devLog = (...msgs) => {
  if (IS_DEVEOPMENT) console.log(...msgs);
};
