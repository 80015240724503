import React from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";

import moment from "moment";

import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from "@material-ui/core";
import { t } from "../../_language";
const PAGE_STRING = "reports.";
class CampusFilter extends React.Component {
  state = {
    programName: "",
    startDate: moment().subtract(1, "month"),
    endDate: moment()
  };

  handleChange = (event, name) => {
    if (name) {
      this.setState({
        [name]: event
      });
    } else {
      let { name, value } = event.target;
      this.setState({
        [name]: value
      });
    }
  };
  applyFilter = () => {
    let filter = {
      startDate: this.state.startDate.format("YYYY-MM-DD"),
      endDate: this.state.endDate.format("YYYY-MM-DD")
    };
    this.props.applyFilter(filter);
  };
  render() {
    let { startDate, endDate } = this.state;
    return (
      <div>
        <Dialog
          // disableBackdropClick
          disableEscapeKeyDown
          open={this.props.filterOpen}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.props.handleClose(event, reason);
            }
          }}
          maxWidth="md"
        >
          <DialogTitle>{t(PAGE_STRING + "filterCampus")}</DialogTitle>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    value={startDate}
                    label={t(PAGE_STRING + "startDate")}
                    onChange={e => this.handleChange(e, "startDate")}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DatePicker
                    value={endDate}
                    label={t(PAGE_STRING + "endDate")}
                    onChange={e => this.handleChange(e, "endDate")}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </MuiPickersUtilsProvider>
          <DialogActions>
            <Button onClick={this.props.handleClose} variant="text">
              {t("buttons.cancel")}
            </Button>
            <Button onClick={this.applyFilter}>{t("buttons.filter")}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default CampusFilter;
