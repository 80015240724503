import {
  generateRequiredFormDataFields,
  generateFetchRequest
} from "../../_helper/apiMethods";
import store from "../../_helper/helper.store";

export const List = () => {
  let {
    user: { password }
  } = store.getState();
  let formData = generateRequiredFormDataFields({
    signatureKey: password.universityUID
  });
  formData.append("universityUID", password.universityUID);

  let url = "/campus/ExternalAccess/list";

  return generateFetchRequest(formData, url);
};

export const Update = (formData, uid) => {
  formData = generateRequiredFormDataFields({ formData, signatureKey: uid });
  formData.append("uid", uid);

  let url = "/campus/ExternalAccess/update";

  return generateFetchRequest(formData, url);
};
