import React from 'react';
// import { PropagateLoader } from 'react-spinners';
import { t } from '../_language';
import {
  TRANS_STRING, 
  // Colors 
} from '../Const';
import {
  Typography,
  DialogContent,
  Box,
  Dialog,
  CircularProgress,
} from '@material-ui/core';
export const LoaderTextType = {
  saving: 1,
  custom: 2,
};
let { API_STRING } = TRANS_STRING;
class LoadingIndicator extends React.PureComponent {
  TEXT_STRINGS = {
    SAVING_STRING: API_STRING + 'saving',
    LOADING_STRING: API_STRING + 'loading',
  };
  getLoadingText = (type, text) => {
    switch (type) {
      case LoaderTextType.saving:
        return t(this.TEXT_STRINGS.SAVING_STRING);
      case LoaderTextType.custom:
        return t(text);
      default:
        return t(this.TEXT_STRINGS.LOADING_STRING);
    }
  };

  state = {
    text: ' ',
    modalVisible: false,
  };

  static instance = null;

  getModalState = () => {
    return this.state.modalVisible;
  };

  showLoader = (type, text) => {
    return new Promise((resolve) => {
      this.setState(
        {
          text: this.getLoadingText(type, text),
          modalVisible: true,
        },
        resolve,
      );
    });
  };

  hideLoader = () => {
    this.setState({
      modalVisible: false,
    });
  };

  render() {
    let { modalVisible, text } = this.state;
    return (
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            this.hideLoader(event, reason);
          }
        }}
        // disableBackdropClick
        disableEscapeKeyDown
        open={modalVisible}
      >
        <DialogContent>
          {modalVisible && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress />

              <Typography variant="subtitle2">{text}</Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}
export default LoadingIndicator;
