import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import { storeUser } from '../../_reducers/reducer.user';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { LoginAPI } from '../../_api';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Button, Typography, Link, Paper, Grid } from '@material-ui/core';
import { TRANS_STRING, NAV_STRINGS } from '../../Const';
import { LoadingIndicator, Footer, Header } from '../../component';

import { toast } from 'react-toastify';
import { t } from '../../_language';

const PAGE_STRING = 'loginSignup.';
const { COMMON_STRING, INPUT_REQ, INPUT_INVALID } = TRANS_STRING;
const theme = createTheme({
  palette: {
    primary: {
      main: '#5E35B1',
      fontFamily: 'Montserrat',
    },
    secondary: {
      main: '#F68E58',
      contrastText: 'white',
    },
    typography: {
      fontFamily: 'Montserrat',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        fontFamily: 'Montserrat',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Montserrat',
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'Montserrat',
      },
    },
  },
});

class ButtonLogin extends Component {
  getInitialData = () => ({
    isEmailView: true,
    isSigninView: true,
    singinModel: {
      userID: '',
      password: '',
    },
  });

  constructor(props) {
    super(props);
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      let { partnerSignupModel } = this.state;
      if (value !== partnerSignupModel.password) {
        return false;
      }
      return true;
    });
    this.state = {
      ...this.getInitialData(),
    };
  }

  onSubmitEmail = async () => {
    await LoadingIndicator.instance.showLoader();
    let { singinModel } = this.state;
    let response = await LoginAPI.Email(singinModel.userID);
    if (response)
      this.setState({
        singinModel: {
          ...singinModel,
          ...response,
        },
        isEmailView: false,
      });
    LoadingIndicator.instance.hideLoader();
  };

  onSubmitPassword = async () => {
    await LoadingIndicator.instance.showLoader();

    let { singinModel } = this.state;
    let response = await LoginAPI.Password(
      singinModel.userUID,
      singinModel.userID,
      singinModel.password,
      singinModel.token,
    );
    if (response) {
      this.props.storeUser(response);
      this.props.history.push('/university/dashboard');
    }
    LoadingIndicator.instance.hideLoader();
  };

  onPressUniversitySignup = async (ev) => {
    await LoadingIndicator.instance.showLoader();
    let formData = new FormData(ev.target);
    let response = await LoginAPI.UniversitySignup(formData);
    if (response)
      this.setState({
        univSignUpSuccess: true,
      });
    LoadingIndicator.instance.hideLoader();
  };

  onChangeSignIn = (event) => {
    let { name, value } = event.target;
    this.setState({
      singinModel: {
        ...this.state.singinModel,
        [name]: value,
      },
    });
  };

  onChangeSignup = (event) => {
    let { name, value } = event.target;
    this.setState({
      univSignupModel: {
        ...this.state.partnerSignupModel,
        [name]: value,
      },
    });
  };
  onResetClick = async () => {
    await LoadingIndicator.instance.showLoader();
    let { singinModel } = this.state;
    let response = await LoginAPI.ForgetPassword(
      singinModel.userUID,
      singinModel.userID,
      singinModel.token,
    );
    if (response) {
      toast.success(response.message);
      this.setState({
        isForgetPassword: false,
        isEmailView: true,
      });
    }
    LoadingIndicator.instance.hideLoader();
  };
  onForgetPasswordClick = () => {
    this.setState({
      isEmailView: true,
      isForgetPassword: true,
    });
  };

  onClickSignup = () => {
    this.props.history.push(NAV_STRINGS.signup);
  };
  onClickLogo = () => {
    this.props.history.push('/');
  };
  render() {
    let { isEmailView, singinModel, isForgetPassword } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Modal
          open={this.state.univSignUpSuccess ? true : false}
          center
          closeOnOverlayClick={false}
          closeOnEsc={false}
          onClose={() => this.setState({ univSignUpSuccess: false })}
        >
          <div
            className="bordermodal"
            style={{
              textAlign: 'center',
              padding: 15,
              fontFamily: 'Montserrat',
            }}
          >
            <h3>Registrasi kamu sudah berhasil.</h3>
            <p>Tim goKampus akan segera menghubungi kamu.</p>
          </div>
        </Modal>

        <Header />
        <Grid
          container
          spacing={3}
          style={{ marginTop: '65px', marginBottom: '200px' }}
        >
          <Grid item md={4}></Grid>
          <Grid item md={4}>
            <Paper elevation={4} style={{ padding: '25px' }}>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} className="text-center">
                  <img
                    src={'assets/images/logo.png'}
                    style={{ width: 248 }}
                    alt="goKampus"
                  />
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      margin: '10px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {t(`${PAGE_STRING}loginTitle`)}
                  </Typography>
                </Grid>
              </Grid>

              {isEmailView ? (
                <React.Fragment>
                  {isForgetPassword ? (
                    <ValidatorForm onSubmit={this.onResetClick}>
                      <TextValidator
                        key="forgetemail"
                        fullWidth
                        autoFocus
                        value={singinModel.userID}
                        name="userID"
                        onChange={this.onChangeSignIn}
                        validators={['required', 'isEmail']}
                        label={t(COMMON_STRING + 'email')}
                        errorMessages={[t(INPUT_REQ), t(INPUT_INVALID)]}
                        margin="normal"
                        variant="outlined"
                      />{' '}
                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '20px',
                          fontWeight: '700',
                          marginTop: '15px',
                        }}
                      >
                        {t('buttons.reset')}
                      </Button>
                    </ValidatorForm>
                  ) : (
                    <ValidatorForm onSubmit={this.onSubmitEmail}>
                      <TextValidator
                        key="loginemail"
                        fullWidth
                        value={singinModel.userID}
                        name="userID"
                        onChange={this.onChangeSignIn}
                        validators={['required', 'isEmail']}
                        label={t(COMMON_STRING + 'email')}
                        errorMessages={[t(INPUT_REQ), t(INPUT_INVALID)]}
                        margin="normal"
                        variant="outlined"
                      />{' '}
                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '20px',
                          fontWeight: '700',
                          marginTop: '15px',
                        }}
                      >
                        {t('buttons.next')}
                      </Button>
                    </ValidatorForm>
                  )}
                </React.Fragment>
              ) : (
                <ValidatorForm onSubmit={this.onSubmitPassword}>
                  <TextValidator
                    key="loginpassword"
                    fullWidth
                    autoFocus
                    value={singinModel.password}
                    name="password"
                    onChange={this.onChangeSignIn}
                    label="Password "
                    validators={['required']}
                    errorMessages={[t(INPUT_REQ)]}
                    type="password"
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '24px',
                      fontWeight: '700',
                      marginTop: '15px',
                    }}
                  >
                    {t('buttons.login')}
                  </Button>
                  <Typography style={{ margin: '10px' }}>
                    <Link
                      component="button"
                      variant="body2"
                      colour="primary"
                      onClick={this.onForgetPasswordClick}
                    >
                      {t('buttons.forgotPassword')}
                    </Link>
                  </Typography>
                </ValidatorForm>
              )}
            </Paper>
            <Paper elevation={4} style={{ padding: '25px', marginTop: '25px' }}>
              <Typography
                variant="h6"
                color="primary"
                style={{
                  margin: '10px',
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat',
                }}
              >
                {t(PAGE_STRING + 'addYourUniv')}
              </Typography>
              <Typography
                color="primary"
                style={{ margin: '10px', fontFamily: 'Montserrat' }}
              >
                {t(PAGE_STRING + 'manageYourUniv')}
              </Typography>
              <Button
                color="primary"
                onClick={this.onClickSignup}
                style={{
                  fontFamily: 'Montserrat',
                  margin: '10px',
                  fontWeight: 'bold',
                }}
              >
                {t('buttons.register')}
              </Button>
            </Paper>
            <br />
            <Typography
              color="primary"
              variant="subtitle2"
              align="center"
              style={{
                margin: '10px',
                fontFamily: 'Montserrat',
                textAlign: 'center',
                paddingTop: '20px',
                fontSize: '16px',
              }}
            >
              {t(PAGE_STRING + 'termsAndAgree')}
            </Typography>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
        <Footer />
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeUser,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(ButtonLogin);
