import ReactGA from 'react-ga';
export const DEFAULT_CONFIG = {
  trackingId: 'G-Z7VPTG8PDZ',
  debug: false,
  gaOptions: {
    cookieDomain: 'none',
  },
};

export const TrackPageHelper = (pathname) => {
  if (pathname === '/') {
    trackPage('Home Page');
  } else {
    trackPage(pathname);
  }
};

export function initReactGA(config) {
  ReactGA.initialize(config);
}

function trackPage(page) {
  ReactGA.set({
    page,
  });
  ReactGA.pageview(page);
}
