import { createTheme } from "@material-ui/core/styles";
import { Colors } from "../Const";
export const defaultFontSize = 15;
export const theme = createTheme({
  zIndex: {
    modal: 100
  },
  palette: {
    // background: Colors.backgroundColor,
    primary: {
      main: Colors.primary
    },
    secondary: {
      main: Colors.secondary,
      contrastText: "#fff"
    },
    error: {
      main: "#FF3D00"
    },
    textSecondary: {
      main: "#fff"
    }
  },
  overrides: {    
    MuiDivider: {
      root: {
        marginTop: 10,
        marginBottom: 10
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: defaultFontSize - 3
      }
    },
    MuiInputLabel: {
      root: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: defaultFontSize
      }
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: "Montserrat",
        fontSize: defaultFontSize
      }
    },
    MuiInput: {
      root: {
        fontFamily: "Montserrat",
        fontSize: defaultFontSize
      },
      inputMultiline: {
        fontFamily: "Montserrat",
        overflow: "visible"
      }
    },
    MuiTypography: {
      subtitle1: {
        fontFamily: "Montserrat",
        fontSize: defaultFontSize
      },
      subtitle2: {
        fontFamily: "Montserrat",
        fontSize: defaultFontSize + 2
      },
      caption: {
        fontFamily: "Montserrat",
        fontSize: defaultFontSize - 2,
        color: Colors.grey
      },
      colorTextSecondary: {
        color: "#fff"
      }
    },
    MuiTable: {
      root: {
        marginTop: 10
      }
    },
    MuiTableCell: {
      sizeSmall: {
        fontSize: defaultFontSize,
        lineHeight: 1.4,
        paddingLeft: 14,
        paddingRight: 14
      },
      head: {
        backgroundColor: Colors.tableHeadColor
      }
    },
    MuiGrid: {
      container: {
        paddingTop: 5,
        paddingBottom: 5
      }
    }
  },
  props: {
    MuiIcon: {
      color: "primary"
    },
    MuiSvgIcon: {
      fontSize: "small"
    },
    MuiIconButton: {
      color: "primary"
    },
    MuiButton: {
      color: "primary",
      variant: "contained"
    },
    MuiTableCell: {
      size: "small"
    },
    MuiCheckbox: {
      color: "primary"
    },
    MuiTextField: {
      fullWidth: true
    },
    MuiFab: {
      variant: "extended",
      color: "secondary",
      size: "medium"
    }
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
    htmlFontSize: defaultFontSize,
    fontSize: defaultFontSize
  }
});
