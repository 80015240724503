import React from "react";
import { Route, Switch } from "react-router-dom";
import { setDefaultTranslations, setDefaultLanguage } from "./_language";
import en from "./_language/en.json";
import id from "./_language/id.json";
import ProtectedPages from "./protectedPages";
import { Login, Signup, DataEntryVerification, HomePage } from "./pages";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./styles/Theme";
import { LoadingIndicator, PrivateRoute } from "./component";
import { NAV_STRINGS } from "./Const";

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    setDefaultTranslations({ id, en });
    setDefaultLanguage("en");
  }
  setLoaderInstance = ref => (LoadingIndicator.instance = ref);
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <LoadingIndicator ref={this.setLoaderInstance} />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            path={`/${NAV_STRINGS.dataEntry}/:token`}
            component={DataEntryVerification}
          />

          <Route exact path={`/${NAV_STRINGS.login}`} component={Login} />
          <Route exact path={`/${NAV_STRINGS.signup}`} component={Signup} />
          <PrivateRoute component={ProtectedPages} path="/university" />
        </Switch>
      </ThemeProvider>
    );
  }
}
export default App;
