import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

//icons
import FilterListIcon from '@material-ui/icons/FilterList';

import Button from '@material-ui/core/Button';

import { t } from '../../_language';
import ProgramFilter from './ProgramFilter';
import CampusFilter from './CampusFilter';

import {
  IconButton,
  TableCell,
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TablePagination,
  Grid,
} from '@material-ui/core';
import { ReportAPI } from '../../_api';
import { LoadingIndicator, PageTitle, PageBody } from '../../component';
import {
  CustomTableHead,
  RenderColumnData,
} from '../../component/tableComponents';
import XLSX from 'xlsx';
import { toast } from 'react-toastify';

const PAGE_STRING = 'reports.';
class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onPageChange(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onPageChange(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onPageChange(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onPageChange(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }
}

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

class ReportList extends React.PureComponent {
  componentDidMount() {
    this.setInitialState();
  }
  componentDidUpdate(prevProps) {
    let {
      match: { params },
    } = this.props;
    if (params.reportName !== prevProps.match.params.reportName) {
      this.setInitialState();
    }
  }
  setInitialState = () => {
    let {
      match: { params },
    } = this.props;
    let reportName = null;
    let columnData = this.getColumnData();
    if (params.reportName.includes('campus')) {
      reportName = 'campus';
      columnData.splice(1, 1);
    } else {
      reportName = 'program';
      columnData.splice(0, 1);
    }
    this.setState({
      reportList: [],
      columnData,
      filterOpen: true,
      reportName,
    });
  };
  getColumnData = () => [
    { id: 'campusName', label: t(PAGE_STRING + 'campusName'), sort: 'asc' },
    { id: 'programName', label: t(PAGE_STRING + 'programName'), sort: false },
    {
      id: 'noofStudentsApplied',
      align: 'right',
      label: t(PAGE_STRING + 'noOfStudentsApplied'),
      sort: false,
    },
    {
      id: 'noofStudentsRejected',
      align: 'right',
      label: t(PAGE_STRING + 'noOfStudentsRejected'),
      sort: false,
    },
    {
      id: 'noofStudentsAccepted',
      align: 'right',
      label: t(PAGE_STRING + 'noOfStudentsAccepted'),
      sort: false,
    },
    {
      id: 'totalStudents',
      align: 'right',
      label: t(PAGE_STRING + 'totalStudents'),
      sort: false,
    },
  ];

  state = {
    reportList: [],
    columnData: [],
    filterOpen: true,
    reportName: '',
    page: 0,
    rowsPerPage: 10,
  };

  onExport = () => {
    try {
      let table = document.getElementById('myTable');
      let { reportName } = this.state;
      const sheet = XLSX.utils.table_to_sheet(table);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet 1');
      XLSX.writeFile(workbook, `${reportName}.xls`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleFilter = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    });
  };

  applyFilter = async (filter) => {
    let { reportName } = this.state;

    LoadingIndicator.instance.showLoader();
    let formData = new FormData();
    formData.append('StartDate', filter.startDate);
    formData.append('EndDate', filter.endDate);
    let response = [];
    if (reportName.includes('campus')) {
      response = await ReportAPI.Campus(formData);
    } else {
      response = await ReportAPI.Program(formData);
    }
    if (response)
      this.setState({
        reportList: response,
        filterOpen: false,
      });

    LoadingIndicator.instance.hideLoader();
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const reportList =
      order === 'desc'
        ? this.state.reportList.sort((a, b) =>
            b[orderBy] < a[orderBy] ? -1 : 1,
          )
        : this.state.reportList.sort((a, b) =>
            a[orderBy] < b[orderBy] ? -1 : 1,
          );

    this.setState({
      page: 0,
      reportList,
      order,
      orderBy,
    });
  };

  renderListItem = (item, id) => {
    return (
      <TableRow key={id}>
        {this.state.columnData.map(RenderColumnData(item))}
      </TableRow>
    );
  };
  render() {
    const {
      reportName,
      filterOpen,
      columnData,
      reportList,
      rowsPerPage,
      page,
    } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, reportList.length - page * rowsPerPage);
    let list = reportList.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
    return (
      <React.Fragment>
        <PageTitle
          title={t(PAGE_STRING + 'title')}
          iconData={[
            {
              icon: <FilterListIcon onClick={this.handleFilter} />,
              tooltip: 'Filter List',
            },
          ]}
        />
        {reportName.includes('program') ? (
          <ProgramFilter
            handleClose={this.handleFilter}
            filterOpen={filterOpen}
            applyFilter={this.applyFilter}
          />
        ) : (
          <CampusFilter
            handleClose={this.handleFilter}
            filterOpen={filterOpen}
            applyFilter={this.applyFilter}
          />
        )}
        <PageBody>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button onClick={this.onExport}>{t('buttons.export')}</Button>
            </Grid>
          </Grid>

          <Table id="myTable">
            <CustomTableHead
              columnData={columnData}
              actionCell={false}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {list.map(this.renderListItem)}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={12}
                  count={reportList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </PageBody>
      </React.Fragment>
    );
  }
}

export default ReportList;
