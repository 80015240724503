import React from "react";
import { Footer, Header } from "../component";
import { Grid, Button, Typography } from "@material-ui/core";
import { NAV_STRINGS } from "../Const";
const HomePage = ({ history }) => {
  return (
    <React.Fragment>
      <Header />
      <div style={{ overflow: "hidden", height: "100vh", width: '100%' }}>
        <div
          style={{
            position: "absolute",
            zIndex: 10,
            top: "30vh",
            width: "100vw"
          }}
        >
          <Grid container spacing={2} style={{ display: 'block' }}>
            <Grid item md={12}>
              <Typography className="banner-text" variant="h3" align="center">
                Get students through mobile app                
              </Typography>
              <Typography className="banner-text2" variant="h4" align="center">
                Digital transformation in college enrollment
              </Typography>
            </Grid>
          </Grid>          
          <Grid container spacing={2}>
            <Grid item xs />
            <Grid item>
              <Button
                color="secondary"
                onClick={() => history.push(NAV_STRINGS.signup)}
                className="btn-orange"
              >
                Sign Up Here
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => history.push(NAV_STRINGS.login)}
                className="btn-blank"
              >
                Login
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </div>
        <img
          src={"/assets/images/university.png"}
          alt="goKampus University"
          style={{ position: "absolute", height: "100vh", width: "100vw", objectFit: "cover" }}
        />
      </div>
      <div className="partnerships">
        <Grid container spacing={3}>
          <Grid item md={5}>
            <img
              src={"/assets/images/univ1.png"}
              alt="goKampus University"
              className="img-responsive"
            />
          </Grid>
          <Grid item md={7} className="partner-right">
            <Typography
              className="textpurple text-bold"
              variant="h5"
            >
              Simplify Your Access to the Best Talents
            </Typography>
            <br />
            <Grid container spacing={3}>
              <Grid item md={2}>
                <img
                  src={"/assets/images/p1.png"}
                  alt="goKampus University"
                  className="img-responsive"
                />
              </Grid>
              <Grid item md={10}>
                <Typography
                  className="text-bold"
                  variant="h5"
                >
                  Save Time and Money
                </Typography>
                <Typography>Digital platform enables online administration for more efficient cost and quicker process</Typography>
              </Grid>
            </Grid>
            <br /><br />
            <Grid container spacing={3}>
              <Grid item md={2}>
                <img
                  src={"/assets/images/p3.png"}
                  alt="goKampus University"
                  className="img-responsive"
                />
              </Grid>
              <Grid item md={10}>
                <Typography
                  className="text-bold"
                  variant="h5"
                >
                  Processed Application Trough One App
                </Typography>
                <Typography>We provide complete service from giving full university information, enabling direct application trough app and processing the online application form.</Typography>
              </Grid>
            </Grid>
            <br /><br />
            <Grid container spacing={3}>
              <Grid item md={2}>
                <img
                  src={"/assets/images/p2.png"}
                  alt="goKampus University"
                  className="img-responsive"
                />
              </Grid>
              <Grid item md={10}>
                <Typography
                  className="text-bold"
                  variant="h5"
                >
                  Transparent Digitized System
                </Typography>
                <Typography>Real time access to university information and all student applicants data.</Typography>                
              </Grid>              
            </Grid>            
          </Grid>
        </Grid>
        <br /><br />
        </div>
        <div className="partnerships">
        <Grid container spacing={3}>
          <Grid item md={12} className="text-center">
            <Typography className="textpurple text-bold" variant="h5">
              Through goKampus you will get all of these Benefits
            </Typography>
          </Grid>
        </Grid>
        <br /><br />
        <Grid container spacing={3} className="container-abu">
          <Grid item md={3}>
            <div className="bg-abu text-center">
              <img
                src={"/assets/images/univ5.png"}
                alt="goKampus University"
                className="img-responsive center-block"
              />
              <br />
              <Typography className="textpurple text-bold">Get Technology &amp;<br />Support</Typography>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="bg-abu text-center">
              <img
                src={"/assets/images/univ2.png"}
                alt="goKampus University"
                className="img-responsive center-block"
              />
              <br />
              <Typography className="textpurple text-bold">Efficient Admission<br />Process</Typography>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="bg-abu text-center">
              <img
                src={"/assets/images/univ3.png"}
                alt="goKampus University"
                className="img-responsive center-block"
              />
              <br />
              <Typography className="textpurple text-bold">More Impact Less<br />Time</Typography>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="bg-abu text-center">
              <img
                src={"/assets/images/univ4.png"}
                alt="goKampus University"
                className="img-responsive center-block"
              />
              <br />
              <Typography className="textpurple text-bold">Wider Reach to<br />Targeted Students</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="partnerships bgabu">
        <Grid container spacing={3} className="text-center">
          <Grid item md={12}>
            <Typography className="textpurple text-bold" variant="h5">Get Started</Typography>
          </Grid>
        </Grid>
        <br /><br />
        <Grid container spacing={3}>
          <Grid item md={3}>
            <div className="text-center">
              <img
                src={"/assets/images/p4.png"}
                alt="goKampus University"
                className="img-responsive center-block"
              />
              <br />
              <Typography className="textpurple text-bold">Step 1</Typography>
              <Typography className="textpurple">Sign up and submit your University details</Typography>
              <br />
              <Button
                color="secondary"
                onClick={() => history.push(NAV_STRINGS.signup)}
                className="btn-orange"
              >
                Sign Up Here
              </Button>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="text-center">
              <img
                src={"/assets/images/p5.png"}
                alt="goKampus University"
                className="img-responsive center-block"
              />
              <br />
              <Typography className="textpurple text-bold">Step 2</Typography>
              <Typography className="textpurple">Our Representative will contact you to complete the registration</Typography>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="text-center">
              <img
                src={"/assets/images/p6.png"}
                alt="goKampus University"
                className="img-responsive center-block"
              />
              <br />
              <Typography className="textpurple text-bold">Step 3</Typography>
              <Typography className="textpurple">Sign in and complete all the programs details that you offer and we will populate them</Typography>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="text-center">
              <img
                src={"/assets/images/p7.png"}
                alt="goKampus University"
                className="img-responsive center-block"
              />
              <br />
              <Typography className="textpurple text-bold">Step 4</Typography>
              <Typography className="textpurple">Start getting your University applied by the students!</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default HomePage;
