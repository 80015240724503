import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export default class SearchBox extends React.Component {
    static propTypes = {
        placeholder: PropTypes.string,
        onPlacesChanged: PropTypes.func,
    };
    render() {
        return (
            <input
                ref="input"
                placeholder={this.props.placeholder}
                type="text"
                style={{position: 'absolute', margin: '12px 0 0 12px', zIndex: '99999'}}
            />
        );
    }
    onPlacesChanged = () => {
        if (this.props.onPlacesChanged) {
            this.props.onPlacesChanged(this.searchBox.getPlaces());
        }
    };
    componentDidMount() {
        var input = ReactDOM.findDOMNode(this.refs.input);
        // eslint-disable-next-line no-undef
        this.searchBox = new google.maps.places.SearchBox(input);
        this.searchBoxListener = this.searchBox.addListener("places_changed", this.onPlacesChanged);
    }
    componentWillUnmount() {
        // eslint-disable-next-line no-undef
        google.maps.event.clearInstanceListeners(this.searchBox);
    }
}