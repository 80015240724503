import { getSignature, generateFetchRequest } from '../_helper/apiMethods';
import { devLog } from '../_helper/commonMethods';
import store from '../_helper/helper.store';
var ip = require('ip');

export const Program = (formData) => {
  let {
    user: { password },
  } = store.getState();
  let signature = password.userUID.toLowerCase() + password.sessionToken;
  devLog('raw sig', signature);
  signature = getSignature(signature);

  formData.append('universityUID', password.universityUID);
  formData.append('Signature', signature);
  formData.append('ActionBy', password.userUID);
  formData.append('DeviceIP', ip.address());

  let url = 'v2/report/program';
  return generateFetchRequest(formData, url, '');
};

export const Campus = (formData) => {
  let {
    user: { password },
  } = store.getState();
  let signature = password.userUID.toLowerCase() + password.sessionToken;
  devLog('raw sig', signature);
  signature = getSignature(signature);

  formData.append('universityUID', password.universityUID);
  formData.append('Signature', signature);
  formData.append('ActionBy', password.userUID);
  formData.append('DeviceIP', ip.address());

  let url = 'v2/report/campus';
  return generateFetchRequest(formData, url, '');
};

export const ExportAll = (formData) => {
  let {
    user: { password },
  } = store.getState();
  let signature = password.userUID.toLowerCase() + password.sessionToken;
  devLog('raw sig', signature);
  signature = getSignature(signature);

  formData.append('Signature', signature);
  formData.append('ActionBy', password.userUID);
  formData.append('DeviceIP', ip.address());

  let url = 'v2/report/studentexportall';
  return generateFetchRequest(formData, url, '');
};
