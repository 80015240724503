import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  IconButton
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import UserDetails from "./UserDetails";
import { connect } from "react-redux";
import { t } from "../../_language";
import { UniversityAPI } from "../../_api";
import { LoadingIndicator, PageTitle, PageBody } from "../../component";
import {
  CustomTableHead,
  RenderColumnData
} from "../../component/tableComponents";
import { TRANS_STRING } from "../../Const";
const PAGE_STRING = "user.";
const { COMMON_STRING } = TRANS_STRING;
class UserList extends React.Component {
  componentDidMount() {
    this.getUserLists();
  }

  state = {
    list: [],
    columnData: [
      {
        id: "firstName",
        label: t(COMMON_STRING + "firstName")
      },
      {
        id: "lastName",
        label: t(COMMON_STRING + "lastName")
      },
      {
        id: "email",
        label: t(COMMON_STRING + "email")
      },
      {
        id: "mobileNo",
        label: t(COMMON_STRING + "mobileNo")
      },
      {
        id: "role",
        label: t(COMMON_STRING + "role")
      },
      {
        id: "locked",
        label: t(COMMON_STRING + "locked"),
        type: "checkbox"
      },
      {
        id: "active",
        label: t(COMMON_STRING + "active"),
        type: "checkbox"
      }
    ]
  };

  getUserLists = async () => {
    LoadingIndicator.instance.showLoader();

    let list = await UniversityAPI.User.List();

    if (list) {
      this.setState({
        list,
        selectedData: null
      });
    }
    LoadingIndicator.instance.hideLoader();
  };
  onCreateUser = () => {
    this.setState({ selectedData: {} });
  };
  onClickEdit = item => {
    this.setState({
      selectedData: {
        ...item,
        locked: item.locked ? item.locked : false
      }
    });
  };
  removeSelected = () => {
    this.setState({
      selectedData: null
    });
  };

  renderListItem = item => {
    return (
      <TableRow key={item.uid}>
        {this.state.columnData.map(RenderColumnData(item))}

        <TableCell padding="none">
          <IconButton onClick={() => this.onClickEdit(item)} aria-label="edit">
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };
  render() {
    let { selectedData, list, columnData } = this.state;
    return (
      <React.Fragment>
        {!selectedData && (
          <React.Fragment>
            <PageTitle
              title={t(PAGE_STRING + "listTitle")}
              iconData={[
                {
                  icon: (
                    <Button size="small" onClick={this.onCreateUser}>
                      {t("buttons.createUser")}
                    </Button>
                  )
                }
              ]}
            />

            <PageBody>
              <Table>
                <CustomTableHead
                  columnData={columnData}
                  actionCell={
                    <TableCell>{t(COMMON_STRING + "tableActions")}</TableCell>
                  }
                />
                <TableBody>{list.map(this.renderListItem)}</TableBody>
              </Table>
            </PageBody>
          </React.Fragment>
        )}
        {selectedData && (
          <UserDetails
            selectedData={selectedData}
            location={this.props.location}
            removeSelected={this.removeSelected}
            refreshList={this.getUserLists}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  master: state.user.master
});
export default connect(
  mapStateToProps,
  null
)(UserList);
