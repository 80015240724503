import React from "react";

import Grid from "@material-ui/core/Grid";

//redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Typography, Dialog, DialogTitle, Button } from "@material-ui/core";
import { t } from "../../_language";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { removeUser } from "../../_reducers/reducer.user";
import { LoginAPI } from "../../_api";
import PageTitle from "../../component/PageTitle";
import PageBody from "../../component/PageBody";
import { TRANS_STRING } from "../../Const";
import { LoadingIndicator } from "../../component";

const PAGE_STRING = "text.changePassword.";
const { VALIDATION_STRING, COMMON_STRING, INPUT_REQ } = TRANS_STRING;
class ChangePassword extends React.Component {
  state = {
    passwordSuccess: false,
    loading: false,
    countDown: 5,
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  handleChange = e => {
    let { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  onChangePassword = async e => {
    await LoadingIndicator.instance.showLoader();
    let formData = new FormData(e.target);
    let response = await LoginAPI.ChangePassword(formData);
    if (response) {
      this.setState({
        passwordSuccess: true
      });
      this.setTimeInverval();
    }
    LoadingIndicator.instance.hideLoader();
  };

  setTimeInverval = () => {
    let countDown = 5;
    let timer = setInterval(() => {
      if (countDown === 0) {
        clearInterval(timer);
        this.props.removeUser();
      } else {
        countDown = countDown - 1;
        this.setState({ countDown });
      }
    }, 1000);
  };

  render() {
    let {
      countDown,
      passwordSuccess,
      oldPassword,
      newPassword,
      confirmPassword
    } = this.state;
    return (
      <React.Fragment>
        <PageTitle title={t(PAGE_STRING + "title")} />
        <PageBody>
          <Dialog open={passwordSuccess}>
            <DialogTitle>{t(PAGE_STRING + "passChangeSuccess")}</DialogTitle>
            <Typography variant="body2" align="center">
              {t(PAGE_STRING + "redirect")}
            </Typography>
            <Typography variant="body1" align="center">
              {countDown}
            </Typography>
          </Dialog>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ValidatorForm onSubmit={this.onChangePassword}>
                <TextValidator
                  margin="normal"
                  name="oldPassword"
                  type="password"
                  onChange={this.handleChange}
                  label={t(COMMON_STRING + "oldPassword")}
                  value={oldPassword}
                  validators={["required"]}
                  errorMessages={[t(INPUT_REQ)]}
                />
                <TextValidator
                  margin="normal"
                  name="newPassword"
                  type="password"
                  onChange={this.handleChange}
                  label={t(COMMON_STRING + "newPassword")}
                  value={newPassword}
                  validators={["required"]}
                  errorMessages={[t(INPUT_REQ)]}
                />
                <TextValidator
                  margin="normal"
                  type="password"
                  name="confirmPassword"
                  onChange={this.handleChange}
                  label={t(COMMON_STRING + "confirmPassword")}
                  value={confirmPassword}
                  validators={["required", `matchRegexp:^${newPassword}`]}
                  errorMessages={[
                    t(INPUT_REQ),
                    t(VALIDATION_STRING + "confirmPassword")
                  ]}
                />
                <Button type="submit">{t("buttons.submit")}</Button>
              </ValidatorForm>
            </Grid>
          </Grid>
        </PageBody>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeUser
    },
    dispatch
  );

const mapStateToProps = state => ({
  user: state.user
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
