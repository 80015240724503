import * as Media from "./media";
import * as Period from "./period";
import * as Student from "./student";
import * as Program from "./program";
import * as ExternalAccess from "./externalAccess";
import * as Campus from "./CampusAPI";
const CampusAPI = {
  ...Campus,
  Media,
  Period,
  Program,
  Student,
  ExternalAccess
};
export default CampusAPI;
