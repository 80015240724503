import React from 'react';

import Details from './ExternalAccessDetails';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from '../../_language';
import {
  Table,
  TableCell,
  TableBody,
  IconButton,
  TableRow,
  Button,
} from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { CampusAPI } from '../../_api';
import { PageTitle, PageBody, LoadingIndicator } from '../../component';
import {
  CustomTableHead,
  RenderColumnData,
} from '../../component/tableComponents';
import { TRANS_STRING, IS_DEVEOPMENT } from '../../Const';
import TestExAccess from './TestExAccess';
const PAGE_STRING = 'externalAccess.';
const { COMMON_STRING } = TRANS_STRING;
class ExternalAccessList extends React.Component {
  componentDidMount() {
    this.getExternalAccessList();
  }

  state = {
    list: [],
    selectedData: null,
    selectedTestData: null,
    columnData: [
      {
        id: 'campusName',
        label: t(COMMON_STRING + 'campusName'),
      },
      {
        id: 'apIkey',
        label: t(COMMON_STRING + 'apiKey'),
      },
      {
        id: 'secretKey',
        label: t(COMMON_STRING + 'secretKey'),
      },
      {
        id: 'triggerURL',
        label: t(COMMON_STRING + 'triggerURL'),
        style: { maxWidth: 200, wordWrap: 'break-word' },
      },
      {
        id: 'apiKeyActive',
        label: t(COMMON_STRING + 'active'),
        type: 'checkbox',
      },
    ],
  };

  getExternalAccessList = async () => {
    await LoadingIndicator.instance.showLoader();
    let response = await CampusAPI.ExternalAccess.List();
    if (response) this.setState({ list: response });
    LoadingIndicator.instance.hideLoader();
  };

  removeSelected = () => {
    this.setState({ selectedData: null });
  };

  onPressDetails = item => e => {
    this.setState({
      selectedData: item,
    });
  };

  removeTestItem = () => {
    this.setState({
      selectedTestData: null,
    });
  };

  onPressTest = item => () => {
    this.setState({
      selectedTestData: item,
    });
  };

  renderListItem = item => {
    return (
      <TableRow key={item.uid}>
        {this.state.columnData.map(RenderColumnData(item))}
        <TableCell>
          <IconButton onClick={this.onPressDetails(item)}>
            <Visibility />
          </IconButton>
        </TableCell>
        {IS_DEVEOPMENT && (
          <TableCell>
            <Button
              onClick={this.onPressTest(item)}
              size="small"
              disabled={!item.triggerURL}
            >
              {t('buttons.test')}
            </Button>
          </TableCell>
        )}
      </TableRow>
    );
  };

  render() {
    let { list, columnData, selectedTestData, selectedData } = this.state;
    let isListVisible = !selectedData && !selectedTestData;
    return (
      <React.Fragment>
        {isListVisible && (
          <React.Fragment>
            <PageTitle title={t(PAGE_STRING + 'listTitle')} />

            <PageBody>
              <Table>
                <CustomTableHead columnData={columnData} />
                <TableBody>{list.map(this.renderListItem)}</TableBody>
              </Table>
            </PageBody>
          </React.Fragment>
        )}

        {selectedData && (
          <Details
            selectedData={selectedData}
            removeSelected={this.removeSelected}
            refreshList={this.getExternalAccessList}
          />
        )}

        {selectedTestData && (
          <TestExAccess
            selectedData={selectedTestData}
            removeSelected={this.removeTestItem}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
const mapStateToProps = state => ({
  user: state.user.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(ExternalAccessList);
