import React from "react";
import { Grid, Typography, Link, makeStyles, Box } from "@material-ui/core";
import { t } from "../_language";
import { BASE_URL, AppVersion } from "../Const";
const PAGE_STRING = "footer.";
const useStyles = makeStyles({
  heading: {
    fontSize: 24,
    fontWeight: "700",
    margin: 10,
    fontFamily: "Montserrat",
    color: "#F68E58"
  },
  subHeading: {
    fontSize: 16,
    margin: 10,
    fontFamily: "Montserrat"
  }
});
const Footer = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} style={{ backgroundColor: "#29292b", paddingTop: 40, paddingBottom: 20 }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Grid container spacing={3}>
          <Grid item md={8}>
            <div style={{ margin: 10 }}>
              <Link href={BASE_URL} target="_blank" style={{ color: "#fff" }}>
                <img
                  src={"/assets/images/logo_white.png"}
                  style={{width: 227}}
                  className={"logoImg"}
                  alt="goKampus"
                />
              </Link>
            </div>
            <Typography color="secondary" variant="subtitle2" style={{
              margin: "10px",
              fontFamily: "Montserrat",
              fontWeight: "700",
              fontSize: "18px",
              color: "#F68E58"
            }}>
              {t(PAGE_STRING + "bestStudents")}
            </Typography>
            <Typography
              classes={{
                root: classes.subHeading
              }}
              style={{
                color: "#fff"
              }}
            >
              <Box component="span" display="block">Rukan Golden Green</Box>
              <Box component="span" display="block">Jl. Panjang No.8C, RT.5/RW.11</Box>
              <Box component="span" display="block">Kedoya Utara, Kec. Kb. Jeruk</Box>
              <Box component="span" display="block">Kota Jakarta Barat</Box>
              <Box component="span" display="block">Daerah Khusus Ibukota Jakarta 11520</Box>
            </Typography>
          </Grid>
          {/* <!--Grid item md={4}>
            <Typography
              color="secondary"
              classes={{
                root: classes.heading
              }}
            >
              {t(PAGE_STRING + "corporate")}
            </Typography>
            <Typography
              classes={{
                root: classes.subHeading
              }}
            >
              <Link href={BASE_URL} target="_blank" style={{ color: "#fff" }}>
                {t("buttons.backToGok")}
              </Link>
            </Typography>
          </Grid--> */}
          <Grid item md={4}>
            <Typography
              color="secondary"
              classes={{
                root: classes.heading
              }}
            >
              {t(PAGE_STRING + "community")}
            </Typography>
            <div style={{ display: "flex" }}>
              <a
                style={{ margin: "10px" }}
                href="https://www.instagram.com/gokampusofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-responsive"
                  src={"/assets/images/s2.png"}
                  alt="goKampus Instagram"
                />
              </a>

              <a
                style={{ margin: "10px" }}
                href="https://twitter.com/goKampus_"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-responsive"
                  src={"/assets/images/s3.png"}
                  alt="goKampus Twitter"
                />
              </a>
              <a
                style={{ margin: "10px" }}
                href="https://www.facebook.com/Gokampusofficial-944187515760171/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-responsive"
                  src={"/assets/images/s5.png"}
                  alt="goKampus Facebook"
                />
              </a>
              <a
                style={{ margin: "10px" }}
                href="https://line.me/R/ti/p/%40ouu9013m"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-responsive"
                  src={"/assets/images/s7.png"}
                  alt="goKampus Line"
                />
              </a>
              <a
                style={{ margin: "10px" }}
                href="https://wa.me/6281574323932"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-responsive"
                  src={"/assets/images/s8.png"}
                  alt="goKampus WhatsApp"
                />
              </a>
              <a
                style={{ margin: "10px" }}
                href="https://www.youtube.com/channel/UCJ2GeQy5ogNk7_3YL6RYWag"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-responsive"
                  src={"/assets/images/s1.png"}
                  alt="goKampus YouTube"
                />
              </a>
            </div>
            <span style={{ display: 'none' }}>Release Version: {AppVersion} </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};
export default Footer;
