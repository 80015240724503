import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
} from '@material-ui/core';
import { t } from '../../_language';
import { TRANS_STRING } from '../../Const';
let { COMMON_STRING } = TRANS_STRING;
class CustomTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    let { columnData } = this.props;
    let id = columnData.findIndex((x) => x.id === property);

    if (id === -1) {
      return;
    }
    columnData = columnData.map((rec, index) => {
      if (id !== index) {
        rec.sort = false;
      }
      return rec;
    });
    let columnItem = columnData[id];
    if (columnItem.sort === 'desc') {
      columnItem.sort = 'asc';
    } else {
      columnItem.sort = 'desc';
    }
    this.props.onRequestSort(columnData);
  };
  getSortDirection = (item) => {
    if (item === 'asc') {
      return 'asc';
    }
    return 'desc';
  };
  renderSortItem = (column) => {
    if (this.props.onRequestSort) {
      return (
        <Tooltip
          title="Sort"
          placement={column.numeric ? 'bottom-end' : 'bottom-start'}
          enterDelay={300}
        >
          <TableSortLabel
            active={column.sort !== false}
            direction={this.getSortDirection(column.sort)}
            onClick={this.createSortHandler(column.id)}
          >
            {column.label}
            {/* {t(this.props.translateKey + column.label)} */}
          </TableSortLabel>
        </Tooltip>
      );
    } else return column.label;
  };
  renderColumnItem = (column) => {
    if (column.visibility !== false)
      if (column.name === 'active')
        return (
          <TableCell
            key={column.id}
            variant="head"
            sortDirection={column.sort ?? false}
          >
            {this.renderSortItem(column)}
          </TableCell>
        );
      else
        return (
          <TableCell
            key={column.id}
            numeric={column.numeric}
            sortDirection={column.sort}
            variant="head"
            style={column.style}
          >
            {this.renderSortItem(column)}
          </TableCell>
        );
    return null;
  };
  render() {
    const { columnData, actionCell } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(this.renderColumnItem)}
          {actionCell === false
            ? null
            : actionCell || (
                <TableCell>{t(COMMON_STRING + 'tableActions')}</TableCell>
              )}
        </TableRow>
      </TableHead>
    );
  }
}

export default CustomTableHead;
