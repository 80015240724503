import React from "react";
import { Route } from 'react-router-dom';

import List from './ReportList';
class Reports extends React.Component {

    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route exact path={`${match.path}/:reportName`} component={List} />
            </React.Fragment>
        )
    }
}

export default Reports