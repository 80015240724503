import React from "react";
import Grid from "@material-ui/core/Grid";

//redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Widget from "./Widget";
import { Paper, Typography, Tooltip } from "@material-ui/core";
import { UniversityAPI } from "../../_api";
import { PageTitle, PageBody } from "../../component";
import { t } from "../../_language";

class Dashboard extends React.Component {
  PAGE_STRING = "text.dashboard.";
  async componentDidMount() {
    let res = await UniversityAPI.Dashboard();
    if (res) {
      this.setState({
        dashboard: res
      });
    }
  }

  state = {
    dashboard: {
      totalData: {
        totalApplied: "",
        totalAccepted: "",
        totalDeclined: "",
        totalAppliedCM: "",
        totalAcceptedCM: "",
        totalDeclinedCM: ""
      },
      performingPrograms: []
    }
  };
  render() {
    let {
      dashboard: { totalData, performingPrograms }
    } = this.state;
    let { PAGE_STRING } = this;
    return (
      <React.Fragment>
        <PageTitle title={t(PAGE_STRING + "title")} />
        <PageBody>
          <Grid container spacing={1}>
            <Grid item xs md={2} sm={4}>
              <Widget
                title={t(PAGE_STRING + "totalApplied")}
                value={totalData.totalApplied}
              />
            </Grid>
            <Grid item xs md={2} sm={4}>
              <Widget
                title={t(PAGE_STRING + "totalAccepted")}
                value={totalData.totalAccepted}
              />
            </Grid>
            <Grid item xs md={2} sm={4}>
              <Widget
                title={t(PAGE_STRING + "totalDeclined")}
                value={totalData.totalDeclined}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs md={2} sm={4}>
              <Widget
                title={t(PAGE_STRING + "totalAppliedCM")}
                value={totalData.totalAppliedCM}
              />
            </Grid>
            <Grid item xs md={2} sm={4}>
              <Widget
                title={t(PAGE_STRING + "totalAcceptedCM")}
                value={totalData.totalAcceptedCM}
              />
            </Grid>
            <Grid item xs md={2} sm={4}>
              <Widget
                title={t(PAGE_STRING + "totalDeclinedCM")}
                value={totalData.totalDeclinedCM}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs md={4}>
              <Paper style={{ borderTop: "5px solid #B388FF", padding: 5 }}>
                <Typography variant="body1">
                  {t(PAGE_STRING + "top10PerformingProgram")}
                </Typography>
                {performingPrograms.map(item => (
                  <div key={item.campusProgramUID}>
                    <div style={{ padding: 10 }}>
                      <Typography variant="subtitle1">
                        {item.campusName}
                      </Typography>
                      <Typography variant="subtitle2">
                        {item.programName} ({item.programType})
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        cursor: "pointer"
                      }}
                    >
                      <Tooltip title={t(PAGE_STRING + "noOfStudents")}>
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: "white",
                            width: 30,
                            textAlign: "center",
                            padding: 5,
                            borderRadius: 50,
                            backgroundColor: "#B388FF"
                          }}
                        >
                          {item.totalApplied}
                        </Typography>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </PageBody>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
const mapStateToProps = state => ({
  user: state.user.user,
  master: state.user.master
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
