import React from "react";
import { TableCell } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

const checkboxCol = /checkbox/i;
const dateRegex = /date/i;
const RenderColumnData = item => colItem => {
  let columnType = colItem.type;
  let columnId = colItem.id;
  let columnData = item[columnId];
  if (colItem.visibility !== false)
    if (checkboxCol.test(columnType))
      return (
        <TableCell key={columnId} padding="checkbox">
          {columnData ? <CheckCircle color="primary" /> : null}
        </TableCell>
      );
    else if (dateRegex.test(columnType))
      return (
        <TableCell key={columnId}>
          {columnData ? columnData.format("DD MMM YYYY") : null}
        </TableCell>
      );
    else
      return (
        <TableCell key={columnId} style={colItem.style}>
          {item[columnId]}
        </TableCell>
      );
};
export default RenderColumnData;
