import React from "react";
import { Tooltip, Typography, Toolbar, IconButton } from "@material-ui/core";
import { translate } from "../../_language";
import { withStyles } from "@material-ui/core/styles";

const toolbarStyles = () => ({
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    display: "flex"
  },
  title: {
    flex: "0 0 auto"
  },
  toolbar: {
    background: "#fff",
    padding: "5px 20px"
  }
});

let CustomTableToolbar = props => {
  const { iconData, title, classes } = props;
  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.title}>
        <Typography variant="headline">{title}</Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {iconData.map((iconObject, index) => (
          <Tooltip key={index} title={iconObject.tooltip}>
            <IconButton
              aria-label={iconObject.tooltip}
              onClick={iconObject.clickAction}
            >
              {iconObject.icon}
            </IconButton>
          </Tooltip>
        ))}
      </div>
    </Toolbar>
  );
};
CustomTableToolbar.defaultProps = {
  iconData: []
};
CustomTableToolbar = withStyles(toolbarStyles)(translate(CustomTableToolbar));
export default CustomTableToolbar;
