import React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { toast } from 'react-toastify';
import {
  Tooltip,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import {
  Functions as FuncttionsIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons';
import { CampusAPI } from '../../_api';
import { t } from '../../_language';
import { PageBody, PageTitle, LoadingIndicator } from '../../component';
import { TRANS_STRING } from '../../Const';
const { COMMON_STRING, INPUT_REQ, INPUT_INVALID } = TRANS_STRING;
const PAGE_STRING = 'externalAccess.';

class ExternalAccessDetails extends React.PureComponent {
  componentDidMount() {
    let { selectedData } = this.props;
    this.setState({
      externalAccessDetails: {
        ...selectedData,
      },
    });
  }

  getInitialState = () => {
    return {
      uid: '',
      triggerURL: '',
      secretKey: '',
      apIkey: '',
      campusName: '',
      apiKeyActive: true,
    };
  };
  constructor() {
    super();
    this.submitRef = React.createRef();
    this.state = {
      loading: false,
      alertModal: false,
      externalAccessDetails: this.getInitialState(),
    };
  }

  handleChange = (e) => {
    let { name, value, type } = e.target;
    if (type === 'checkbox') {
      this.setState({
        externalAccessDetails: {
          ...this.state.externalAccessDetails,
          [name]: e.target.checked,
        },
      });
    } else {
      this.setState({
        externalAccessDetails: {
          ...this.state.externalAccessDetails,
          [name]: value,
        },
      });
    }
  };

  handleSubmit = async (e) => {
    let { externalAccessDetails, alertModal } = this.state;
    let { selectedData } = this.props;
    if (
      !alertModal &&
      selectedData.secretKey !== externalAccessDetails.secretKey
    ) {
      this.setState({ alertModal: true });
      return;
    }

    LoadingIndicator.instance.showLoader(t('apiMessages.saving'));
    let formData = new FormData(e.target);
    formData.append('secretKey', externalAccessDetails.secretKey);
    formData.set('apiKeyActive', externalAccessDetails.apiKeyActive);
    formData.append('uid', externalAccessDetails.uid);

    let response = await CampusAPI.ExternalAccess.Update(
      formData,
      selectedData.uid,
    );
    if (response) {
      this.props.refreshList();
      this.props.removeSelected();
      toast.success(t('apiMessages.saved'));
    } else LoadingIndicator.instance.hideLoader();
  };

  onGoback = () => {
    this.props.removeSelected();
  };

  generateAlphaNumericString = () => {
    var arr = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
      ],
      letterUsed = 0,
      numberUsed = 0,
      i = 0,
      randomStr = '';
    for (; i < 15; i++) {
      if (
        (Math.floor(Math.random() * 2) === 0 && numberUsed < 8) ||
        letterUsed >= 4
      ) {
        randomStr += Math.floor(Math.random() * 10);
        numberUsed++;
      } else {
        randomStr += arr[Math.floor(Math.random() * 26)];
        letterUsed++;
      }
    }
    this.setState({
      externalAccessDetails: {
        ...this.state.externalAccessDetails,
        secretKey: randomStr,
      },
    });
  };
  handleClose = () => {
    this.setState({ alertModal: false });
  };
  copyToClipboard = () => {
    var copyText = document.getElementById('secretKeyInput');
    copyText.select();
    document.execCommand('copy');
  };
  render() {
    let { externalAccessDetails, alertModal } = this.state;
    return (
      <React.Fragment>
        <Dialog open={alertModal} onClose={this.handleClose}>
          <DialogTitle>SecretKey Changed</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are trying to save new generated Secret Key. Please make sure
              you do the necessary changes in your calling application.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ fontSize: 20 }}
              onClick={this.handleClose}
              color="primary"
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              style={{ fontSize: 20 }}
              onClick={() => this.submitRef.current.click()}
              color="primary"
            >
              {t('buttons.save')}
            </Button>
          </DialogActions>
        </Dialog>
        <PageTitle
          title={t(PAGE_STRING + 'detailsTitle')}
          iconData={[
            {
              icon: (
                <Button size="small" onClick={this.onGoback}>
                  {t('buttons.goback')}
                </Button>
              ),
            },
          ]}
        />
        <PageBody>
          <ValidatorForm onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'campusName')}
                  onChange={this.handleChange}
                  name="campusName"
                  value={externalAccessDetails.campusName || ''}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  id="secretKeyInput"
                  label={t(COMMON_STRING + 'secretKey')}
                  onChange={this.handleChange}
                  name="secretKey"
                  value={externalAccessDetails.secretKey || ''}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={t(COMMON_STRING + 'generateNewKey')}
                          placement="top"
                        >
                          <IconButton
                            aria-label="Generate New Key"
                            onClick={this.generateAlphaNumericString}
                          >
                            <FuncttionsIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={t(COMMON_STRING + 'copyToClipboard')}
                          placement="top"
                        >
                          <IconButton
                            aria-label="Copy to clipboard"
                            onClick={this.copyToClipboard}
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'triggerURL')}
                  onChange={this.handleChange}
                  name="triggerURL"
                  value={externalAccessDetails.triggerURL || ''}
                  validators={[
                    'required',
                    'matchRegexp:^(http://www.|https://www.|http://|https://|www.)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
                  ]}
                  errorMessages={[t(INPUT_REQ), t(INPUT_INVALID)]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'apiKey')}
                  onChange={this.handleChange}
                  name="apIkey"
                  value={externalAccessDetails.apIkey || ''}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  className={'bgPurple'}
                  control={
                    <Checkbox
                      name="apiKeyActive"
                      checked={externalAccessDetails.apiKeyActive}
                      onChange={this.handleChange}
                    />
                  }
                  label={t(COMMON_STRING + 'active')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button ref={this.submitRef} type="submit">
                {t('buttons.save')}
              </Button>
            </Grid>
          </ValidatorForm>
        </PageBody>
      </React.Fragment>
    );
  }
}

export default ExternalAccessDetails;
