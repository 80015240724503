import React from 'react';

//components
import {
  Typography,
  Button,
  Grid,
  // GridListTile,
  // Paper,
  Divider,
  Checkbox,
  // GridList,
  Table,
  TableBody,
  TableCell,
  TableRow,
  FormControlLabel,
  Fab,
  IconButton,
  Dialog,
  DialogContent,
  TableContainer,
  makeStyles,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { DatePicker } from '@material-ui/pickers';

import ChipInput from 'material-ui-chip-input';

import moment from 'moment';
import AvatarEditor from 'react-avatar-editor';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  // CloudUpload, 
  CheckCircle, 
  Edit as EditIcon,
  School
} from '@material-ui/icons';
import { t } from '../../_language';
import { ReactSelectValidator } from '../../component/validators';
import { UniversityAPI, CampusAPI } from '../../_api';
import { 
  TRANS_STRING, 
  BASE_URL,
  GOOGLEKEY, 
} from '../../Const';
import { PageTitle, PageBody, LoadingIndicator } from '../../component';
import {
  RenderColumnData,
  CustomTableHead,
} from '../../component/tableComponents';
import { LoaderTextType } from '../../component/Loader';
import GoogleMap from 'google-map-react';
import SearchBox from './SearchBox';
// import Geocode from "react-geocode";
import { Helmet } from "react-helmet";

const {
  COMMON_STRING,
  INPUT_INVALID,
  INPUT_REQ,
  API_STRING,
  SAVED_STRING,
  VALIDATION_STRING,
} = TRANS_STRING;
const PAGE_STRING = 'univCMS.';
const CAMPUS_STR = PAGE_STRING + 'campus.';
const PERIOD_STR = PAGE_STRING + 'period.';
const PROGRAM_STR = PAGE_STRING + 'program.';
const MEDIA_STR = PAGE_STRING + 'media.';

// const potraitImageSize = {
//   width: 280,
//   height: 360,
// };
const landScapeImageSize = {
  width: 360,
  height: 180,
};

// Geocode.setApiKey("AIzaSyBNUAZD906sC6lRC7oRhMLatVTYYadW9Hs");
// Geocode.setLocationType("ROOFTOP");
// Geocode.enableDebug();

class UniversityCMS extends React.Component {
  async componentDidMount() {
    ValidatorForm.addValidationRule('minmaxWords', (value) => {
      let length = value ? value.split(' ').length : 0;
      if (length > 151) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('multiRequired', (value) => {
      if (value && value.length) return true;
      return false;
    });

    LoadingIndicator.instance.showLoader();
    let response = await UniversityAPI.Detail();
    if (response) {
      let univType = this.getUnivType({
        gsp: response.gsp,
        overseas: response.overseas,
      });

      let { master } = this.props;
      this.classTypeList = this.getClssTypeList(response.overseas);
      this.campusSchoolTypeList = master.campusSchoolTypeList.filter((item) =>
        item.addInfo1.includes(univType),
      );
      this.universityProgramTypeList = master.universityProgramTypeList.filter(
        (item) => item.addInfo2?.includes(univType),
      );
      this.programFeeList = master.programFeeList.filter((item) =>
        item.addInfo2.includes(univType),
      );
      this.setState(
        {
          university: {
            ...this.state.university,
            ...response,
            descriptionEn: response.descriptionEn || '',
            descriptionID: response.descriptionID || '',
          },
        },
        this.getCampusList,
      );
    } else LoadingIndicator.instance.hideLoader();
  }

  classTypeList = [];
  campusSchoolTypeList = [];
  universityProgramTypeList = [];
  programFeeList = [];

  constructor(props) {
    super(props);
    this.state = {
      mediaProgress: 0,
      mediaProgressLength: 0,
      programSelected: -1,
      periodSelected: -1,
      gotoList: false,
      loading: false,
      saving: 'Saving...',
      mediaedit: false,
      email: '',
      addCampus: false,
      addProgram: false,
      addPeriod: false,
      periods: [],
      programs: [],
      selectedMedia: null,
      newMedia: {
        file: null,
        campusMediaUID: null,
        defaultMedia: false,
        mediaType: null,
        mediaURL: null,
        active: true,
      },
      mediafiles: {
        default: null,
        video: null,
        others: [],
      },
      newCampus: this.getInitialCampus(),
      newProgram: this.getInititalPorgram(),
      newPeriod: this.getInitialPeriod(),
      university: {
        universityName: '',
        descriptionEn: '',
        descriptionID: '',
        notes: '',
        address: '',
        phone: '',
        email: '',
        overseas: false,
        gsp: false,
        campus: [],
      },
      campusColumnData: [
        {
          id: 'campusName',
          label: t(CAMPUS_STR + 'univ/campusName'),
        },
        {
          id: 'defaultCampus',
          label: t(CAMPUS_STR + 'mainCampus'),
          type: 'checkbox',
        },
        {
          id: 'accreditation',
          label: t(CAMPUS_STR + 'accreditation'),
        },
        {
          id: 'city',
          label: t(CAMPUS_STR + 'city'),
        },
        {
          id: 'province',
          label: t(CAMPUS_STR + 'province'),
        },
      ],
      periodColumnData: [
        {
          id: 'periodName',
          label: t(PERIOD_STR + 'name'),
        },
        {
          id: 'fromDate',
          label: t(PERIOD_STR + 'from'),
          type: 'date',
        },
        {
          id: 'toDate',
          label: t(PERIOD_STR + 'to'),
          type: 'date',
        },
        {
          id: 'active',
          label: t(COMMON_STRING + 'active'),
          type: 'checkbox',
        },
      ],
      programColumnData: [
        {
          id: 'programName',
          label: t(PROGRAM_STR + 'name'),
        },
        {
          id: 'studyLevelName',
          label: t(PROGRAM_STR + 'type'),
        },
        {
          id: 'accreditationName',
          label: t(PROGRAM_STR + 'accreditation'),
        },
        {
          id: 'active',
          label: t(COMMON_STRING + 'active'),
          type: 'checkbox',
        },
      ],
      data: [],
      isDisableUpload: true,
      isLocationEmpty: false,
    };
  }

  getClssTypeList = (overseas) => {
    if (overseas)
      return [
        { text: 'On Campus' },
        // { text: 'Start Online' },
        { text: 'Online' },
      ];
    return [{ text: 'Full Time' }, { text: 'Part Time' }, { text: 'Online' }];
  };

  getInititalPorgram = () => ({
    campusProgramUID: null,
    campusUID: null,
    programName: '',
    programNameUID: null,
    programNameID: '',
    studyTypeName: '',
    studyTypeUID: null,
    studyLevelName: '',
    studyLevelUID: null,
    durationName: '',
    durationUID: null,
    languageName : '',
    languageUID: null,
    programCategoryName : '',
    accreditationName: '',
    accreditationUID: null,
    descriptionEN: '',
    descriptionID: '',
    noofStudents: 0,
    active: true,
    fees: '',
    // processingFees: 0
  });

  getInitialPeriod = () => ({
    uid: null,
    periodName: '',
    active: true,
    fromDate: moment(),
    toDate: moment(),
  });

  getInitialCampus = () => ({
    active: true,
    termperiod: [],
    accreditation: '',
    campusName: '',
    email: '',
    descriptionEn: '',
    descriptionID: '',
    defaultCampus: false,
    country: '',
    city: '',
    address: '',
    province: '',
    postalcode: '',
    campusSchoolType: '',
    campusClassType: '',
    latitude: null,
    longitude: null,
  });

  getInitialMedia = () => ({
    campusMediaUID: null,
    active: true,
    file: null,
    mediaType: null,
    defaultMedia: false,
    mediaURL: null,
  });

  getProgramList = async (programData = {}, shouldShowLoader = true) => {
    let { newCampus } = this.state;
    if (shouldShowLoader) await LoadingIndicator.instance.showLoader();
    let response = await CampusAPI.Program.List(newCampus.campusUID);

    if (response) {
      this.setState({
        ...programData,
        programs: response,
      });
    }
    if (shouldShowLoader) LoadingIndicator.instance.hideLoader();
  };

  getPeriodList = async (periodData = {}, shouldShowLoader = true) => {
    let { newCampus } = this.state;
    if (shouldShowLoader) await LoadingIndicator.instance.showLoader();
    let response = await CampusAPI.Period.List(newCampus.campusUID);
    if (response) {
      this.setState({
        ...periodData,
        periods: response.map((item) => {
          item.fromDate = moment(item.fromDate);
          item.toDate = moment(item.toDate);
          return item;
        }),
      });
    }
    if (shouldShowLoader) LoadingIndicator.instance.hideLoader();
  };

  getCampusList = async (refreshCache) => {
    LoadingIndicator.instance.showLoader();
    let response = await CampusAPI.List({
      filter: { city: '', programName: '', freeText: '' },
      MOUAgreed: '',
      refreshCache: refreshCache,
    });

    if (response) {
      let { university } = this.state;
      this.setState({
        university: {
          ...university,
          campus: response.map((item) => {
            item.termperiod = item.termperiod ? item.termperiod.split(',') : [];
            return item;
          }),
        },
      });
    }
    LoadingIndicator.instance.hideLoader();
  };

  getUnivType = ({ gsp, overseas }) => {
    if (gsp) return 'Instant';
    if (overseas) return 'Overseas';
    return 'Regular';
  };

  editCampus = (item) => {
    let {
      university: { campus },
    } = this.state;
    let defaultCampusExists = false;
    if (!item.defaultCampus) {
      for (let i = 0; i < campus.length; i++) {
        if (campus[i].campusUID !== item.campusUID) {
          if (campus[i].defaultCampus) {
            defaultCampusExists = campus[i].defaultCampus;
            break;
          }
        }
      }
    }
    this.setState(
      {
        gotoList: true,
        addCampus: true,
        addProgram: true,
        addPeriod: true,
        periods: [],
        programs: [],
        defaultCampusExists,
        newCampus: {
          ...item,
          campusClassType:
            item.campusClassType
              ?.split(',')
              .map((item) => ({ value: item, label: item })) ?? '',
          campusSchoolType:
            item.campusSchoolType
              ?.split(',')
              .map((item) => ({ value: item, label: item })) ?? '',
          descriptionEn: item.descriptionEn || '',
          descriptionID: item.descriptionID || '',
        },
      },
      this.getAllList,
    );
  };

  getAllList = async () => {
    await this.getPeriodList();
    await this.getProgramList(false);
    await this.getMediaList(false);
  };

  handlePeriod = (value, name, type) => {
    if (type === 'edit') {
      this.setState({
        periodSelected: name,
        newPeriod: value,
      });
    } else if (type === 'change') {
      this.setState({
        newPeriod: {
          ...this.state.newPeriod,
          [name]: value,
        },
      });
    } else if (type === 'reset') {
      this.setState({
        newPeriod: {
          uid: null,
          active: true,
          periodName: '',
          fromDate: moment(),
          toDate: moment(),
        },
        periodSelected: -1,
      });
    }
  };

  onCheckMainCampus = (e) => {
    let { checked } = e.target;
    if (checked) {
      let { university } = this.state;
      this.setState({
        newCampus: {
          ...this.state.newCampus,
          defaultCampus: true,
          email: university.email,
          campusName: university.universityName,
          descriptionID: university.descriptionID,
          descriptionEn: university.descriptionEn,
        },
      });
    } else {
      this.setState({
        newCampus: {
          ...this.state.newCampus,
          defaultCampus: false,
        },
      });
    }
  };

  handleChange = (e, group) => {
    let { name, value } = e.target;
    if (group === 'campus') {
      this.setState({
        newCampus: {
          ...this.state.newCampus,
          [name]: value,
        },
      });
    } else {
      this.setState({
        university: {
          ...this.state.university,
          [name]: value,
        },
      });
    }
  };

  handleGMapClick = (props) => {
    const lat = props.lat;
    const lng = props.lng;

    this.setState({
      newCampus: {
        ...this.state.newCampus,
        latitude: lat,
        longitude: lng,
      },
      isLocationEmpty: false,
    });
    console.log("handle click", lat, lng)
    // console.log(x, y, lat, lng, event)

    // Geocode.fromLatLng(lat, lng).then(
    //   (response) => {
    //     const address = response.results[0].formatted_address;
    //     console.log(address);
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );
  }

  handleGMapSearch = (event) => {
    const lat = event[0].geometry.location.lat();
    const lng = event[0].geometry.location.lng();

    this.setState({
      newCampus: {
        ...this.state.newCampus,
        latitude: lat,
        longitude: lng,
      },
      isLocationEmpty: false,
    });
    console.log("handle search", lat, lng, event[0])

    const address = event[0].formatted_address;
    let route, city, state, country, regency, postalcode;
    for (let i = 0; i < event[0].address_components.length; i++) {
      for (let j = 0; j < event[0].address_components[i].types.length; j++) {
        switch (event[0].address_components[i].types[j]) {
          case "route":
            route = event[0].address_components[i].long_name;
            break;
          case "locality":
            city = event[0].address_components[i].long_name;
            break;
          case "administrative_area_level_2":
            regency = event[0].address_components[i].long_name;
            break;
          case "administrative_area_level_1":
            state = event[0].address_components[i].long_name;
            break;
          case "country":
            country = event[0].address_components[i].long_name;
            break;
          case "postal_code":
            postalcode = event[0].address_components[i].long_name;
            break;

          default:
            break;
        }
      }
    }
    console.log(route || '', city || regency, state, country, postalcode || '');
    console.log(address);

    if(!this.state.newCampus.address){
      this.setState({
        newCampus: {
          ...this.state.newCampus,
          address: address,
        },
      });
    }

    if(!this.state.newCampus.postalcode && postalcode){
      this.setState({
        newCampus: {
          ...this.state.newCampus,
          postalcode: postalcode,
        },
      });
    }

    if(!this.state.newCampus.country && country){
      this.setState({
        newCampus: {
          ...this.state.newCampus,
          country: country,
          city: '',
          province: '',
        },
      });
    }
  }

  onChangeCountry = (ev) => {
    this.setState({
      newCampus: {
        ...this.state.newCampus,
        country: ev.target.value,
        city: '',
        province: '',
      },
    });
  };

  handleChip = (chip, name, id) => {
    let termperiod = [...this.state.newCampus.termperiod];

    if (name === 'add') {
      termperiod.push(chip);
    } else if (name === 'delete') {
      termperiod.splice(id, 1);
    }
    this.setState({
      newCampus: {
        ...this.state.newCampus,
        termperiod,
      },
    });
  };

  saveAndAddCampus = async (e) => {
    let { university } = this.state;
    LoadingIndicator.instance.showLoader(LoaderTextType.saving);

    let formData = new FormData(e.target);
    formData.append('active', true);
    let response = await UniversityAPI.Update(formData);
    if (response) {
      toast.success(t(SAVED_STRING));
      if (university.campus.length > 0) {
        // this.setState({
        //   gotoList: true,
        // })
      } else {
        this.setState({
          periods: [],
          programs: [],
          addCampus: true,
          addPeriod: false,
          addProgram: false,
          gotoList: true,
          programSelected: -1,
          newCampus: this.getInitialCampus(),
          newProgram: this.getInititalPorgram(),
          newPeriod: this.getInitialPeriod(),
        });
      }
    }
    LoadingIndicator.instance.hideLoader();
  };

  addCampus = () => {
    let {
      university: { campus },
    } = this.state;
    let defaultCampusExists = false;
    for (let i = 0; i < campus.length; i++) {
      if (campus[i].defaultCampus) {
        defaultCampusExists = campus[i].defaultCampus;
        break;
      }
    }
    this.setState({
      periods: [],
      programs: [],
      addCampus: true,
      addPeriod: false,
      addProgram: false,
      gotoList: true,
      programSelected: -1,
      defaultCampusExists,
      mediafiles: {
        default: null,
        video: null,
        others: [],
      },
      newCampus: this.getInitialCampus(),
      newProgram: this.getInititalPorgram(),
      newPeriod: this.getInitialPeriod(),
    });
  };

  clearAll = () => {
    this.setState({
      programSelected: -1,
      periodSelected: -1,
      addProgram: false,
      addCampus: false,
      addPeriod: false,
      gotoList: false,
      newCampus: this.getInitialCampus(),
      newProgram: this.getInititalPorgram(),
      newPeriod: this.getInitialPeriod(),
      programs: [],
      periods: [],
    });
  };

  saveCampus = async (e) => {
    let { newCampus } = this.state;
    if(!newCampus.latitude || !newCampus.longitude){
      this.setState({
        isLocationEmpty: true
      })
      return;
    }
    LoadingIndicator.instance.showLoader(LoaderTextType.saving);

    let formData = new FormData();
    for (var key in newCampus) {
      formData.append(key, newCampus[key] ?? '');
    }
    if (newCampus.campusClassType)
      formData.set(
        'campusClassType',
        newCampus.campusClassType.map(({ label }) => label),
      );
    if (newCampus.campusSchoolType)
      formData.set(
        'campusSchoolType',
        newCampus.campusSchoolType.map(({ label }) => label),
      );

    formData.set('termperiod', newCampus.termperiod.toString());
    formData.set('defaultCampus', newCampus.defaultCampus);
    formData.delete('campusUID');
    formData.delete('universityName');

    let response = null;
    let updateData = {
      addPeriod: true,
      addProgram: true,
    };
    if (newCampus.campusUID) {
      response = await CampusAPI.Update(formData, newCampus.campusUID);
    } else {
      response = await CampusAPI.Add(formData);
      updateData = {
        ...updateData,
        newCampus: {
          ...newCampus,
          campusUID: response?.retUID,
        },
      };
    }
    if (response) {
      this.setState({ ...updateData });
      this.getCampusList(true);
      toast.success(t(SAVED_STRING));
    } else LoadingIndicator.instance.hideLoader();
  };

  savePeriod = async (e) => {
    let { newPeriod, newCampus } = this.state;
    LoadingIndicator.instance.showLoader(LoaderTextType.saving);
    let formData = new FormData();
    for (var key in newPeriod) {
      if (key.includes('Date')) {
        formData.append(key, newPeriod[key].format('YYYY/MM/DD'));
      } else {
        formData.append(key, newPeriod[key]);
      }
    }
    formData.append('campusUID', newCampus.campusUID);

    let response = null;
    if (!newPeriod.campusPeriodUID) {
      formData.delete('campusPeriodUID');
      response = await CampusAPI.Period.Add(formData);
    } else {
      formData.append('uid', newPeriod.campusPeriodUID);
      response = await CampusAPI.Period.Update(formData, newCampus.campusUID);
    }
    if (response) {
      this.getPeriodList({
        periodSelected: -1,
        newPeriod: this.getInitialPeriod(),
        addProgram: true,
      });
      toast.success(t(SAVED_STRING));
    } else LoadingIndicator.instance.hideLoader();
  };

  handleProgram = async (type, e, id) => {
    let newProgram = { ...this.state.newProgram };
     if (type === 'change') {
     
      let { name, value, id } = e.target;
      if(name === 'programName')
      {
        let { master } = this.props;
        let selectedProgramDetails = master.universityProgramList.filter(function (e) {
          return e.id === id;
        });
        let pTypeName = selectedProgramDetails[0].addInfo3;
        let selectedProgramType = master.studyTypeList.filter(function (e) {
          return e.text.toLowerCase() === pTypeName.toLowerCase();
        });
        let pTypeUID = selectedProgramType[0].id;
        let pLevel = selectedProgramDetails[0].addInfo2;
        let selectedProgramLevel = master.studyLevelList.filter(function (e) {
          return e.text.toLowerCase() === pLevel.toLowerCase();
        });
        let pLevelUID = selectedProgramLevel[0].id;
        let pCategory = selectedProgramDetails[0].addInfo4;
        let pDescEN = selectedProgramDetails[0].addInfo5;
        let pDescID = selectedProgramDetails[0].addInfo6;
        let pNameID = selectedProgramDetails[0].addInfo9;
        let pDuration = selectedProgramDetails[0].addInfo7;
        let selectedProgramDuration = master.durationList.filter(function (e) {
          return e.text.toLowerCase() === pDuration.toLowerCase();
        });
        let pDurationUID = selectedProgramDuration[0].id;
        let pLanguage = selectedProgramDetails[0].addInfo8;
        let selectedProgramLanguage = master.programLanguageList.filter(function (e) {
          return e.text.toLowerCase() === pLanguage.toLowerCase();
        });
        let pLanguageUID = selectedProgramLanguage[0].id;
        this.setState({
          newProgram: {
            ...newProgram,
            [name]: value,
            programNameUID : id,
            studyTypeName: pTypeName,
            studyTypeUID: pTypeUID,
            studyLevelName : pLevel,
            studyLevelUID: pLevelUID,
            programCategoryName: pCategory,
            durationName: pDuration,
            durationUID : pDurationUID,
            languageName : pLanguage,
            languageUID: pLanguageUID,
            descriptionEN: pDescEN || '',
            descriptionID:pDescID || '',
            programNameID:pNameID || ''
          },
        });
      
      }else
      {
        if(name === 'studyTypeName')
        {
          this.setState({
            newProgram: {
              ...newProgram,
              [name]: value,
              studyTypeUID : id
              
            },
          });
        }else if(name === 'studyLevelName')
        {
          this.setState({
            newProgram: {
              ...newProgram,
              [name]: value,
              studyLevelUID : id
              
            },
          });
        }else if(name === 'durationName')
        {
          this.setState({
            newProgram: {
              ...newProgram,
              [name]: value,
              durationUID : id
              
            },
          });
        }else if(name === 'languageName')
        {
          this.setState({
            newProgram: {
              ...newProgram,
              [name]: value,
              languageUID : id
              
            },
          });
        }else if(name === 'accreditationName')
        {
          this.setState({
            newProgram: {
              ...newProgram,
              [name]: value,
              accreditationUID : id
              
            },
          });
        }
        else
        {
          this.setState({
            newProgram: {
              ...newProgram,
              [name]: value
              
            },
          });
        }
      }
   
    } else if (type === 'reset') {
      this.setState({
        newProgram: this.getInititalPorgram(),
        programSelected: -1,
      });
    } else {
      if (type === 'edit') {
        let { programs } = this.state;
        
        this.setState({
          programSelected: id,
          newProgram: {
            ...programs[id],
            processingFees: '',
            descriptionEN: programs[id].descriptionEN || '',
            descriptionID: programs[id].descriptionID || ''
           
          
          },
        });
      } else if (type === 'save') {
        let { newProgram, newCampus } = this.state;
        LoadingIndicator.instance.showLoader(LoaderTextType.saving);

        let formData = new FormData();
        formData.append('campusUID', newCampus.campusUID);
        for (var key in newProgram) {
          formData.append(key, newProgram[key]);
        }
        let response = null;
        if (newProgram.campusProgramUID) {
          formData.append('UID', newProgram.campusProgramUID);
          response = await CampusAPI.Program.Update(formData);
        } else response = await CampusAPI.Program.Add(formData);

        if (response) {
          this.getProgramList({
            programSelected: -1,
            newProgram: this.getInititalPorgram(),
          });
          toast.success(t(SAVED_STRING));
        } else LoadingIndicator.instance.hideLoader();
      }
    }
  };

  getBlobFromImageCanvas = () => {
    return new Promise((resolve) => {
      this.editor.getImage().toBlob((blob) => resolve(blob));
      // this.editor.getImageScaledToCanvas().toBlob((blob) => resolve(blob));
    });
  };

  onPressDefaultMedia = (type, e) => {
    let { newMedia, mediafiles } = this.state;

    let selectedMedia = 'default';

    if (type === 'add' || type === 'update') {
      let { files } = e.target;

      let img = new Image()
      img.src = window.URL.createObjectURL(files[0])
      img.onload = () => {
        console.log("width", img.width, "height", img.height);
        console.log("ratio", img.width/img.height, ": 1")

        if(img.width/img.height === 2 && img.width >= 800 && img.height >= 400){
          this.setState({isDisableUpload: false});
        }else{
          this.setState({isDisableUpload: true});
          toast.error("The image doesn't meet requirement.");
        }
      }

      if (files.length < 1) {
        return;
      }
      this.setState({
        mediaedit: true,
        selectedMedia,
        newMedia: {
          ...newMedia,
          defaultMedia: true,
          file: files[0],
          mediaType: files[0].type,
        },
      });
    } else if (type === 'edit') {
      this.setState({
        mediaedit: true,
        selectedMedia,
        newMedia: {
          ...mediafiles.default,
        },
      });
    }
  };

  onChangeMediaActive = (e) => {
    let { name } = e.target;

    this.setState({
      newMedia: {
        ...this.state.newMedia,
        [name]: e.target.checked,
      },
    });
  };

  onPressOtherMedia = (type, e) => {
    let { newMedia, mediafiles } = this.state;

    let selectedMedia = 'others';
    if (type === 'add') {
      let { files } = e.target;

      let img = new Image()
      img.src = window.URL.createObjectURL(files[0])
      img.onload = () => {
        console.log("width", img.width, "height", img.height);
        console.log("ratio", img.width/img.height, ": 1")

        if(img.width/img.height === 2 && img.width >= 800 && img.height >= 400){
          this.setState({isDisableUpload: false});
        }else{
          this.setState({isDisableUpload: true});
          toast.error("The image doesn't meet requirement.");
        }
      }

      if (files.length < 1) {
        return;
      }
      if (mediafiles.others.length === 9) {
        toast.error('9 Files Already Attached.');
        return;
      }

      this.setState({
        mediaedit: true,
        selectedMedia,
        newMedia: {
          ...newMedia,
          defaultMedia: false,
          file: files[0],
          mediaType: files[0].type,
        },
      });
    } else if(type === 'update'){
      let { files } = e.target;

      let img = new Image()
      img.src = window.URL.createObjectURL(files[0])
      img.onload = () => {
        console.log("width", img.width, "height", img.height);
        console.log("ratio", img.width/img.height, ": 1")

        if(img.width/img.height === 2 && img.width >= 800 && img.height >= 400){
          this.setState({isDisableUpload: false});
        }else{
          this.setState({isDisableUpload: true});
          toast.error("The image doesn't meet requirement.");
        }
      }
      
      if (files.length < 1) {
        return;
      }

      this.setState({
        mediaedit: true,
        selectedMedia,
        newMedia: {
          ...newMedia,
          defaultMedia: false,
          file: files[0],
          mediaType: files[0].type,
        },
      });
    } else if (type === 'edit') {
      this.setState({
        mediaedit: true,
        selectedMedia,
        newMedia: e,
      });
    }
  };

  onChangeMediaInModal = (e) => {
    let { selectedMedia } = this.state;
    if (selectedMedia === 'default') this.onPressDefaultMedia('update', e);
    else if (selectedMedia === 'video') this.onPressVideo('update', e);
    else if (selectedMedia === 'others') this.onPressOtherMedia('update', e);
  };

  onUploadMedia = async () => {
    await LoadingIndicator.instance.showLoader(LoaderTextType.saving);
    let { newMedia, newCampus } = this.state;

    let formData = new FormData();
    formData.append('active', newMedia.active);
    formData.append('DefaultMedia', newMedia.defaultMedia);
    formData.append('campusUID', newCampus.campusUID);
    formData.append('MediaType', newMedia.mediaType);
    if (newMedia.file) {
      if (newMedia.mediaType.includes('image')) {
        let blob = await this.getBlobFromImageCanvas();
        formData.set('MediaType', blob.type);
        formData.append('file', blob);
      } else {
        formData.append('file', newMedia.file);
      }
    } else {
      formData.append('MediaURL', newMedia.mediaURL);
    }
    let response = null;
    if (newMedia.campusMediaUID) {
      formData.append('uid', newMedia.campusMediaUID);
      response = await CampusAPI.Media.Update(formData);
    } else response = await CampusAPI.Media.Add(formData);

    if (response) {
      this.getMediaList(false);
      toast.success(t(SAVED_STRING));
    } else LoadingIndicator.instance.hideLoader();
  };

  onPressVideo = (type, e) => {
    let { newMedia, mediafiles } = this.state;

    let selectedMedia = 'video';
    if (type === 'add' || type === 'update') {
      let { files } = e.target;

      this.setState({isDisableUpload: false});

      if (files.length < 1) {
        return;
      }
      this.setState({
        mediaedit: true,
        selectedMedia,
        newMedia: {
          ...newMedia,
          defaultMedia: false,
          file: files[0],
          mediaType: files[0].type,
        },
      });
    } else if (type === 'edit') {
      this.setState({
        mediaedit: true,
        selectedMedia,
        newMedia: {
          ...mediafiles.video,
        },
      });
    }
  };

  onPressCloseMediaModal = () => {
    this.setState({
      newMedia: this.getInitialMedia(),
      mediaedit: false,
    });
  };

  gotoList = () => {
    this.setState({
      addCampus: false,
      gotoList: false,
    });
    this.clearAll();
  };

  getMediaList = async (shouldShowLoader = true) => {
    let { newCampus } = this.state;
    if (shouldShowLoader) await LoadingIndicator.instance.showLoader();
    let response = await CampusAPI.Media.List(newCampus.campusUID);
    if (response) {
      let defaultMedia;
      let videoMedia;
      let others = [];
      response.map((d) => {
        if (d.defaultMedia) {
          defaultMedia = d;
        } else if (d.mediaType.includes('video')) {
          videoMedia = d;
        } else {
          others.push(d);
        }
        return null;
      });

      this.setState({
        mediaedit: false,
        mediafiles: {
          default: defaultMedia,
          video: videoMedia,
          others: others,
        },
      });
    }
    LoadingIndicator.instance.hideLoader();
  };

  setEditorRef = (editor) => (this.editor = editor);

  publishCampus = async (item) => {
    await LoadingIndicator.instance.showLoader(LoaderTextType.saving);

    let response = await CampusAPI.Publish(true, item.campusUID);
    if (response) {
      toast.success(t(API_STRING + 'publishSuccess'));
      this.getCampusList(true);
    }
    LoadingIndicator.instance.hideLoader();
  };

  onPressCampusPreview = (item, id) => (e) => {
    window.open(
      BASE_URL +
        'universitas/' +
        // id +
        //"/" +
        item.campusUID,
      //"/" +
      //item.campusName
      '_blank',
    );
  };

  renderCampusListItem = (item, id) => {
    return (
      <TableRow key={item.campusUID}>
        {this.state.campusColumnData.map(RenderColumnData(item))}
        <TableCell>
          {item.publish ? (
            t(COMMON_STRING + 'yes')
          ) : (
            <Button onClick={() => this.publishCampus(item)} variant="text">
              {t('buttons.publish')}
            </Button>
          )}
        </TableCell>
        <TableCell>{item.active && <CheckCircle color="primary" />}</TableCell>
        <TableCell>
          <Grid container alignItems="center" justifyContent="flex-end">
            <Grid item xs="auto">
              {item.publish && (
                <Fab
                  onClick={this.onPressCampusPreview(item, id)}
                  variant="extended"
                  size="small"
                  color="secondary"
                >
                  {t('buttons.preview')}
                </Fab>
              )}
            </Grid>
            <Grid item xs="auto">
              <IconButton
                onClick={() => this.editCampus(item)}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  renderPeriodListItem = (item, id) => {
    return (
      <TableRow
        key={item.campusPeriodUID}
        selected={this.state.periodSelected === id}
      >
        {this.state.periodColumnData.map(RenderColumnData(item))}
        <TableCell>
          <IconButton
            onClick={() => this.handlePeriod(item, id, 'edit')}
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  renderProgramListIem = (item, id) => {
    return (
      <TableRow
        key={item.campusProgramUID}
        selected={this.state.programSelected === id}
      >
        {this.state.programColumnData.map(RenderColumnData(item))}
        <TableCell>
          <IconButton
            onClick={() => this.handleProgram('edit', null, id)}
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { master } = this.props;
    let {
      programColumnData,
      campusColumnData,
      periodColumnData,
      addCampus,
      addProgram,
      addPeriod,
      gotoList,
      university,
      newCampus,
      newProgram,
      newPeriod,
      newMedia,
      periods,
      programs,
      mediafiles,
      isDisableUpload,
      isLocationEmpty,
    } = this.state;
    const GmapsMarker = () => <School style={{position: "absolute", transform: 'translate(-50%, -50%)', color: '#551FC1'}} />
    return (
      <React.Fragment>
        <Helmet>
          <script type="text/javascript" src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLEKEY}&libraries=places,geometry`}></script>
        </Helmet>
        <PageTitle title={t('univCMS.title')} />
        <PageBody>
          <ValidatorForm onSubmit={this.saveAndAddCampus}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'univName')}
                  onChange={this.handleChange}
                  name="universityName"
                  value={university.universityName || ''}
                  validators={['required']}
                  errorMessages={[t(INPUT_REQ)]}
                />
              </Grid>
              <Grid item xs />
              <Grid item xs={12} md={6}>
                <TextValidator
                  label="Description English"
                  onChange={this.handleChange}
                  name="descriptionEn"
                  multiline
                  maxRows="4"
                  value={university.descriptionEn || ''}
                  validators={['minmaxWords']}
                  errorMessages={[t(VALIDATION_STRING + 'maxWords150')]}
                  helperText={`Words Remaining ${
                    151 - university.descriptionEn.split(' ').length
                  }`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'descriptionSecondary')}
                  onChange={this.handleChange}
                  name="descriptionID"
                  multiline
                  maxRows="4"
                  value={university.descriptionID || ''}
                  validators={['required', 'minmaxWords']}
                  errorMessages={[
                    t(INPUT_REQ),
                    t(VALIDATION_STRING + 'maxWords150'),
                  ]}
                  helperText={`Words Remaining ${
                    151 - university.descriptionID.split(' ').length
                  }`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'email')}
                  onChange={this.handleChange}
                  name="email"
                  value={university.email || ''}
                  validators={['required', 'isEmail']}
                  errorMessages={[t(INPUT_REQ), t(INPUT_INVALID)]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'phone')}
                  onChange={this.handleChange}
                  name="phone"
                  type="number"
                  value={university.phone || ''}
                  validators={['required']}
                  errorMessages={[t(INPUT_REQ)]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'address')}
                  onChange={this.handleChange}
                  name="address"
                  multiline
                  maxRows="4"
                  value={university.address || ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'notes')}
                  onChange={this.handleChange}
                  name="notes"
                  multiline
                  maxRows="4"
                  value={university.notes || ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'univType')}
                  name="universityType"
                  disabled
                  value={university.universityType || ''}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox name="gsp" checked={university.gsp} disabled />
                  }
                  label={t(CAMPUS_STR + 'gsp')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="overseas"
                      checked={university.overseas}
                      disabled
                    />
                  }
                  label={t(CAMPUS_STR + 'overseas')}
                />
              </Grid>

              <Grid item xs>
                <Button type="submit">
                  {university.campus.length > 0
                    ? t('buttons.save')
                    : t('buttons.saveAddCampus')}
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
          {university.campus.length > 0 && (
            <Grid container>
              <Grid item xs />
              {gotoList && (
                <Grid container item xs={12} md={2} justifyContent="flex-end">
                  <Button onClick={this.gotoList}>
                    {t('buttons.gotoList')}
                  </Button>
                </Grid>
              )}
              {!addCampus && (
                <Grid container item xs={12} md={2} justifyContent="flex-end">
                  <Button onClick={this.addCampus}>
                    {t('buttons.addCampus')}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
          {!addCampus && (
            <Table>
              <CustomTableHead
                columnData={campusColumnData}
                actionCell={
                  <React.Fragment>
                    <TableCell>{t(CAMPUS_STR + 'published')}</TableCell>

                    <TableCell>{t(COMMON_STRING + 'active')}</TableCell>

                    <TableCell>{t(COMMON_STRING + 'tableActions')}</TableCell>
                  </React.Fragment>
                }
              />
              <TableBody>
                {university.campus.map(this.renderCampusListItem)}
              </TableBody>
            </Table>
          )}

          {addCampus && (
            <React.Fragment>
              <Divider />
              <ValidatorForm onSubmit={this.saveCampus}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={this.state.defaultCampusExists}
                          name="defaultCampus"
                          checked={newCampus.defaultCampus}
                          onChange={(e) => this.onCheckMainCampus(e)}
                        />
                      }
                      label={t(CAMPUS_STR + 'mainCampus')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t(CAMPUS_STR + 'name')}
                      onChange={(e) => this.handleChange(e, 'campus')}
                      name="campusName"
                      value={newCampus.campusName}
                      validators={['required']}
                      errorMessages={[t(INPUT_REQ)]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t(CAMPUS_STR + 'email')}
                      onChange={(e) => this.handleChange(e, 'campus')}
                      name="email"
                      value={newCampus.email}
                      validators={['required', 'isEmail']}
                      errorMessages={[t(INPUT_REQ), t(INPUT_INVALID)]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label="Campus Description English"
                      onChange={(e) => this.handleChange(e, 'campus')}
                      name="descriptionEn"
                      multiline
                      maxRows="4"
                      value={newCampus.descriptionEn}
                      validators={['minmaxWords']}
                      errorMessages={[t(VALIDATION_STRING + 'maxWords150')]}
                      helperText={`Words Remaining ${
                        151 - newCampus.descriptionEn.split(' ').length
                      }`}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t(CAMPUS_STR + 'descriptionSecondary')}
                      onChange={(e) => this.handleChange(e, 'campus')}
                      name="descriptionID"
                      multiline
                      maxRows="4"
                      value={newCampus.descriptionID}
                      validators={['required', 'minmaxWords']}
                      errorMessages={[
                        t(INPUT_REQ),
                        t(VALIDATION_STRING + 'maxWords150'),
                      ]}
                      helperText={`Words Remaining ${
                        151 - newCampus.descriptionID.split(' ').length
                      }`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{paddingBottom: "12px"}}>
                      <TextValidator
                        label={t(CAMPUS_STR + 'address')}
                        onChange={(e) => this.handleChange(e, 'campus')}
                        name="address"
                        multiline
                        maxRows="4"
                        value={newCampus.address}
                      />
                    </div>
                    <div style={{padding: "12px 0"}}>
                      <ReactSelectValidator
                        label={t(CAMPUS_STR + 'country')}
                        onChange={this.onChangeCountry}
                        name="country"
                        value={newCampus.country}
                        data={master.countryList}
                        validators={['required']}
                        errorMessages={[t(INPUT_REQ)]}
                      />
                    </div>
                    <div style={{padding: "12px 0"}}>
                      <ReactSelectValidator
                        label={t(CAMPUS_STR + 'city')}
                        onChange={(e) => this.handleChange(e, 'campus')}
                        name="city"
                        value={newCampus.city}
                        data={master.cityList.filter(
                          (item) => item.addInfo1 === newCampus.country,
                        )}
                        validators={['required']}
                        errorMessages={[t(INPUT_REQ)]}
                      />
                    </div>
                    <div style={{padding: "12px 0"}}>
                      <ReactSelectValidator
                        label={t(CAMPUS_STR + 'province')}
                        onChange={(e) => this.handleChange(e, 'campus')}
                        name="province"
                        value={newCampus.province}
                        data={master.provinceList.filter(
                          (item) => item.addInfo1 === newCampus.country,
                        )}
                        validators={['required']}
                        errorMessages={[t(INPUT_REQ)]}
                      />
                    </div>
                    <div style={{padding: "12px 0"}}>
                      <TextValidator
                        label={t(CAMPUS_STR + 'postalCode')}
                        onChange={(e) => this.handleChange(e, 'campus')}
                        name="postalcode"
                        type="number"
                        value={newCampus.postalcode}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ height: '280px', width: '100%', position: 'relative' }}>
                      <SearchBox
                        placeholder={"Search..."}
                        onPlacesChanged={this.handleGMapSearch}
                      />
                      <GoogleMap
                        // bootstrapURLKeys={{
                        //   key: GOOGLEKEY,
                        //   libraries:['places']
                        // }}
                        // onGoogleApiLoaded={this.initGeocoder}
                        // yesIWantToUseGoogleMapApiInternals
                        defaultCenter={{
                          lat: -2.548926,
                          lng: 118.0148634
                        }}
                        center={{
                          lat: Number(newCampus.latitude) || -2.548926,
                          lng: Number(newCampus.longitude) || 118.0148634
                        }}
                        defaultZoom={2}
                        zoom={ newCampus.latitude && newCampus.longitude ? 15 : 2 }
                        onClick={this.handleGMapClick}
                        options={{
                          fullscreenControl: false
                        }}
                      >
                        {newCampus.latitude && newCampus.longitude ?
                          <GmapsMarker
                            lat={Number(newCampus.latitude)}
                            lng={Number(newCampus.longitude)}
                          />
                        : <></>}
                      </GoogleMap>
                    </div>
                    <Typography variant="caption">
                      Latitude: {newCampus.latitude} &nbsp; Longitude: {newCampus.longitude}
                    </Typography>
                    {isLocationEmpty && <p className="MuiFormHelperText-root Mui-error">Location must be selected</p>}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ChipInput
                      fullWidth
                      type="text"
                      label={t(CAMPUS_STR + 'term')}
                      name="termperiod"
                      value={newCampus.termperiod}
                      onAdd={(chip) => this.handleChip(chip, 'add')}
                      onDelete={(chip, index) =>
                        this.handleChip(chip, 'delete', index)
                      }
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ReactSelectValidator
                      style={{ marginTop: 7 }}
                      label={t(CAMPUS_STR + 'accreditation')}
                      value={newCampus.accreditation || ''}
                      name="accreditation"
                      onChange={(e) => this.handleChange(e, 'campus')}
                      data={master.accreditationList}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ReactSelectValidator
                      label={t(CAMPUS_STR + 'campusType')}
                      value={newCampus.campusClassType || ''}
                      name="campusClassType"
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={(e) => this.handleChange(e, 'campus')}
                      data={this.classTypeList}
                      validators={['multiRequired']}
                      errorMessages={[t(INPUT_REQ)]}
                    />
                  </Grid>
                  {university.overseas && (
                    <Grid item xs={12} md={6}>
                      <ReactSelectValidator
                        label={t(CAMPUS_STR + 'schoolType')}
                        value={newCampus.campusSchoolType || ''}
                        name="campusSchoolType"
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(e) => this.handleChange(e, 'campus')}
                        data={master.campusSchoolTypeList}
                        validators={['multiRequired']}
                        errorMessages={[t(INPUT_REQ)]}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="active"
                          checked={newCampus.active}
                          onChange={(e) => {
                            this.setState({
                              newCampus: {
                                ...this.state.newCampus,
                                active: e.target.checked,
                              },
                            });
                          }}
                        />
                      }
                      label={t('active')}
                    />
                  </Grid>
                  <Grid item xs>
                    <Button type="submit">
                      {newCampus.campusUID
                        ? t('buttons.save')
                        : t('buttons.continue')}
                    </Button>
                  </Grid>
                </Grid>
                <Divider />
              </ValidatorForm>

              {addPeriod && (
                <React.Fragment>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <ValidatorForm onSubmit={this.savePeriod}>
                        <Typography variant="caption">
                          {t(PERIOD_STR + 'period')}
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextValidator
                              label={t(PERIOD_STR + 'name')}
                              onChange={(e) =>
                                this.handlePeriod(
                                  e.target.value,
                                  'periodName',
                                  'change',
                                )
                              }
                              name="periodName"
                              value={newPeriod.periodName}
                              validators={['required']}
                              errorMessages={[t(INPUT_REQ)]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <DatePicker
                              label={t(PERIOD_STR + 'from')}
                              value={newPeriod.fromDate}
                              onChange={(e) =>
                                this.handlePeriod(e, 'fromDate', 'change')
                              }
                              format="DD MMMM"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <DatePicker
                              label={t(PERIOD_STR + 'to')}
                              format="DD MMMM"
                              onChange={(e) =>
                                this.handlePeriod(e, 'toDate', 'change')
                              }
                              value={newPeriod.toDate}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="active"
                                  checked={newPeriod.active}
                                  onChange={(e) => {
                                    this.setState({
                                      newPeriod: {
                                        ...this.state.newPeriod,
                                        active: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                              }
                              label={t('active')}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} justifyContent="flex-end">
                          <Grid item xs={12} md={2}>
                            <Fab
                              onClick={() =>
                                this.handlePeriod(null, null, 'reset')
                              }
                            >
                              {t('buttons.clear')}
                            </Fab>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Fab type="submit">{t('buttons.save')}</Fab>
                          </Grid>
                        </Grid>
                      </ValidatorForm>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Table>
                        <CustomTableHead columnData={periodColumnData} />
                        <TableBody>
                          {periods.map(this.renderPeriodListItem)}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              )}

              {addProgram && (
                <React.Fragment>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ValidatorForm
                        onSubmit={() => this.handleProgram('save')}
                      >
                        <Typography variant="caption">
                          {t(PROGRAM_STR + 'program')}
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <ReactSelectValidator
                              label={t(PROGRAM_STR + 'name')}
                              onChange={(e) => this.handleProgram('change', e)}
                              name="programName"
                              value={newProgram.programName}
                              id={newProgram.programNameUID}
                              validators={['required']}
                              errorMessages={[t(INPUT_REQ)]}
                              data={master.universityProgramList}
                            />
                          </Grid>
                          <Grid item xs={12}>
                          <TextValidator
                              label="Program Name ID"
                              name="programNameID"
                              value={newProgram.programNameID}
                              disabled
                            />
                         
                           
                          </Grid>
                          <Grid item xs={12}>
                            <TextValidator
                              label="Program Category"
                              name="programCategoryName"
                              value={newProgram.programCategoryName}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12}>
                          <ReactSelectValidator
                              label="Study Type"
                              name="studyTypeName"
                              value={newProgram.studyTypeName}
                              id={newProgram.studyTypeUID}
                              onChange={(e) => this.handleProgram('change', e)}
                              validators={['required']}
                              errorMessages={[t(INPUT_REQ)]}
                              data={master.studyTypeList}
                            />
                           
                          </Grid>
                          <Grid item xs={12}>
                        
                          <ReactSelectValidator
                              label="Study Level"
                              name="studyLevelName"
                              value={newProgram.studyLevelName}
                              id={newProgram.studyLevelUID}
                              onChange={(e) => this.handleProgram('change', e)}
                              validators={['required']}
                              errorMessages={[t(INPUT_REQ)]}
                              data={master.studyLevelList}
                          />
                          </Grid>
                          <Grid item xs={12}>
                          <ReactSelectValidator
                              label="Duration"
                              name="durationName"
                              value={newProgram.durationName}
                              id={newProgram.durationUID}
                              onChange={(e) => this.handleProgram('change', e)}
                              validators={['required']}
                              errorMessages={[t(INPUT_REQ)]}
                              data={master.durationList}
                            />
                           
                          </Grid>
                          <Grid item xs={12}>
                          <ReactSelectValidator
                              label="Language"
                              name="languageName"
                              value={newProgram.languageName}
                              id={newProgram.languageUID}
                              onChange={(e) => this.handleProgram('change', e)}
                              validators={['required']}
                              errorMessages={[t(INPUT_REQ)]}
                              data={master.programLanguageList}
                            />
                           
                         
                          </Grid>
                         
                          <Grid item xs={12}>
                            <ReactSelectValidator
                              label={t(PROGRAM_STR + 'accreditation')}
                              value={newProgram.accreditationName || ''}
                              name="accreditationName"
                              id={newProgram.accreditationUID}
                              onChange={(e) => this.handleProgram('change', e)}
                              validators={['required']}
                              errorMessages={[t(INPUT_REQ)]}
                              data={master.accreditationList}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextValidator
                              label={t(COMMON_STRING + 'descriptionSecondary')}
                              onChange={(e) => this.handleProgram('change', e)}
                              name="descriptionID"
                              multiLine
                              maxRows="4"
                              value={newProgram.descriptionID}
                              validators={['required', 'minmaxWords']}
                              errorMessages={[
                                t(INPUT_REQ),
                                t(VALIDATION_STRING + 'maxWords150'),
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextValidator
                              label="Description English"
                              onChange={(e) => this.handleProgram('change', e)}
                              name="descriptionEN"
                              multiLine
                              maxRows="4"
                              value={newProgram.descriptionEN}
                              validators={['minmaxWords']}
                              errorMessages={[
                                t(VALIDATION_STRING + 'maxWords150'),
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ReactSelectValidator
                              label={t(PROGRAM_STR + 'fees')}
                              onChange={(e) => this.handleProgram('change', e)}
                              name="fees"
                              value={newProgram.fees || ''}
                              validators={['required']}
                              errorMessages={[t(INPUT_REQ)]}
                              data={master.programFeeList}
                            />
                          </Grid>
                          {/* <Grid item xs={12}>
                            <TextValidator
                              label={t(PROGRAM_STR + "processingFees")}
                              onChange={e => this.handleProgram("change", e)}
                              name="processingFees"
                              type="number"
                              value={newProgram.processingFees || ""}
                            />
                          </Grid> */}
                          <Grid item xs={12}>
                            <TextValidator
                              label={t(PROGRAM_STR + 'noOfStudent')}
                              onChange={(e) => this.handleProgram('change', e)}
                              name="noofStudents"
                              type="number"
                              value={newProgram.noofStudents}
                              validators={['required']}
                              errorMessages={[t(INPUT_REQ)]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="active"
                                  checked={newProgram.active}
                                  onChange={(e) =>
                                    this.setState({
                                      newProgram: {
                                        ...this.state.newProgram,
                                        active: e.target.checked,
                                      },
                                    })
                                  }
                                />
                              }
                              label={t('active')}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent="flex-end">
                          <Grid item xs={12} md={2}>
                            <Fab onClick={() => this.handleProgram('reset')}>
                              {t('buttons.clear')}
                            </Fab>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Fab type="submit">{t('buttons.save')}</Fab>
                          </Grid>
                        </Grid>
                      </ValidatorForm>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ProgramListWrapper>
                        <Table>
                          <CustomTableHead columnData={programColumnData} />
                          <TableBody>
                            {programs.map(this.renderProgramListIem)}
                          </TableBody>
                        </Table>
                      </ProgramListWrapper>
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              )}

              {addProgram && (
                <React.Fragment>
                  <Typography variant="caption">
                    {t(MEDIA_STR + 'media')}
                  </Typography>

                  <Box paddingY={2}>
                    <Typography>{t(MEDIA_STR + 'defaultMedia')}</Typography>
                  </Box>
                  <Box>
                    {mediafiles.default ? (
                      <Box width={landScapeImageSize.width}>
                        <Card onClick={() => this.onPressDefaultMedia('edit')}>
                          <CardActionArea>
                            <CardMedia
                              style={{
                                height: landScapeImageSize.height,
                                width: landScapeImageSize.width,
                              }}
                              image={mediafiles.default.mediaURL}
                              title="CampusMedia"
                            />
                          </CardActionArea>
                        </Card>
                      </Box>
                    ) : (
                      <UploadButton
                        onChange={(e) => this.onPressDefaultMedia('add', e)}
                        id="defaultMedia"
                        accept="image/*"
                        title={t('buttons.chooseFile')}
                      />
                    )}
                  </Box>
                  <Box paddingY={1}>
                    <Typography variant="caption">
                      {t(MEDIA_STR + 'landscapeRatio')}
                    </Typography>
                  </Box>

                  <Box paddingY={2}>
                    <Typography>{t(MEDIA_STR + 'otherMedia')}</Typography>
                  </Box>
                  <Box>
                    <UploadButton
                      onChange={(e) => this.onPressOtherMedia('add', e)}
                      id="defaultMedia"
                      accept="image/*"
                      title={t('buttons.chooseFile')}
                    />
                  </Box>

                  <Box paddingY={1}>
                    <Typography variant="subtitle2">
                      {t(MEDIA_STR + 'youCanUpload')}{' '}
                      <strong>9 {t(MEDIA_STR + 'image')}s</strong>
                    </Typography>
                    <Typography variant="caption">
                      {t(MEDIA_STR + 'landscapeRatio')}
                    </Typography>
                  </Box>

                  <Grid container spacing={3}>
                    {mediafiles.others.map((media, id) => (
                      <Grid item xs="auto" key={id}>
                        <Card
                          onClick={() => this.onPressOtherMedia('edit', media)}
                        >
                          <CardActionArea>
                            <CardMedia
                              style={{
                                height: landScapeImageSize.height,
                                width: landScapeImageSize.width,
                              }}
                              image={media.mediaURL}
                              title="CampusMeida"
                            />
                          </CardActionArea>
                          <CardActions>
                            <FormControlLabel
                              style={{ margin: 0 }}
                              control={
                                <Checkbox checked={media.active} disabled />
                              }
                              label={t('active')}
                            />
                          </CardActions>
                        </Card>
                        {/* <Paper
                          elevation={0}
                          style={{
                            padding: 3,
                            cursor: 'pointer',
                            border: '1px solid lightgrey',
                          }}
                          onClick={() => this.handleMedia('edit', media)}
                        >
                          <img
                            alt="media file"
                            height={180}
                            width={360}
                            src={media.mediaURL}
                          />

                          <FormControlLabel
                            style={{ margin: 0 }}
                            control={<Checkbox checked={media.active} />}
                            label={t('active')}
                          />
                        </Paper> */}
                      </Grid>
                    ))}
                  </Grid>
                  <Box paddingY={2}>
                    <Typography>Video</Typography>
                  </Box>
                  <Box width={400}>
                    {mediafiles.video ? (
                      <Card onClick={() => this.onPressVideo('edit')}>
                        <CardActionArea>
                          <video width="400">
                            <source
                              src={mediafiles.video.mediaURL}
                              type={mediafiles.video.mediaType}
                            />
                            Browser kamu tidak mendukung HTML5 video.
                          </video>
                        </CardActionArea>
                        <CardActions>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={mediafiles.video.active}
                                disabled
                              />
                            }
                            label={t('active')}
                          />
                        </CardActions>
                      </Card>
                    ) : (
                      <UploadButton
                        onChange={(e) => this.onPressVideo('add', e)}
                        id="videoMedia"
                        accept="video/*"
                        title={t('buttons.chooseFile')}
                      />
                    )}
                  </Box>

                  <Dialog
                    // disableBackdropClick
                    disableEscapeKeyDown
                    fullWidth
                    maxWidth="xs"
                    open={this.state.mediaedit}
                    onClose={() => {}}
                  >
                    <DialogContent>
                      {newMedia.mediaType && (
                        <div className="bordermodalplain">
                          <Typography variant="subtitle1">
                            {newMedia.mediaType.includes('image')
                              ? t(MEDIA_STR + 'imagePreview')
                              : t(MEDIA_STR + 'videoPreview')}
                          </Typography>
                          <Grid container>
                            <Grid item sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="active"
                                    checked={newMedia.active}
                                    onChange={this.onChangeMediaActive}
                                  />
                                }
                                label={t('active')}
                              />
                            </Grid>
                          </Grid>
                          <Grid container justifyContent="center">
                            {newMedia.mediaType.includes('image') && (
                              <ImageEditor
                                selectedMedia={this.state.selectedMedia}
                                image={newMedia.file || newMedia.mediaURL}
                                getRef={this.setEditorRef}
                              />
                            )}
                            {newMedia.mediaType.includes('video') && (
                              <video width="400" controls>
                                <source
                                  src={
                                    newMedia.file
                                      ? URL.createObjectURL(newMedia.file)
                                      : newMedia.mediaURL
                                  }
                                  type={newMedia.mediaType}
                                />
                                Browser kamu tidak mendukung HTML5 video.
                              </video>
                            )}
                          </Grid>

                          <Grid container justifyContent="flex-end">
                            {newMedia.campusMediaUID && (
                              <UploadButton
                                id="updateMedia"
                                accept={
                                  newMedia.mediaType.includes('image')
                                    ? 'image/*'
                                    : 'video/*'
                                }
                                onChange={this.onChangeMediaInModal}
                                title={t('buttons.changeFile')}
                              />
                            )}
                            <Box width={5} />

                            <Button
                              onClick={this.onPressCloseMediaModal}
                              variant="text"
                            >
                              {t('buttons.cancel')}
                            </Button>
                            <Box width={5} />
                            <Button onClick={this.onUploadMedia} variant="text" disabled={isDisableUpload}>
                              {t('buttons.save')}
                            </Button>
                          </Grid>
                        </div>
                      )}
                    </DialogContent>
                  </Dialog>
                  <Divider />
                </React.Fragment>
              )}
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} md={1}>
                  <Button onClick={() => this.clearAll()}>
                    {t('buttons.finish')}
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </PageBody>
      </React.Fragment>
    );
  }
}

const useStyle = makeStyles({
  tableContainer: {
    maxHeight: 600,
  },
});
const ProgramListWrapper = (props) => {
  const style = useStyle();
  return (
    <TableContainer className={style.tableContainer}>
      {props.children}
    </TableContainer>
  );
};

const ImageEditor = ({ selectedMedia, image, getRef }) => {
  let height, width;
  // if (selectedMedia === 'default') {
  //   height = potraitImageSize.height;
  //   width = potraitImageSize.width;
  // } else {
    height = landScapeImageSize.height;
    width = landScapeImageSize.width;
  // }

  return (
    <AvatarEditor
      ref={getRef}
      image={image}
      border={0}
      height={height}
      width={width}
      style={{ border: '1px solid grey' }}
      color={[192, 192, 192, 0.6]} // RGBA
    />
  );
};

const UploadButton = ({ onChange, id, accept, title }) => {
  return (
    <>
      <input
        style={{ display: 'none' }}
        id={id}
        type="file"
        accept={accept}
        onChange={onChange}
      />
      <Button
        // disabled={this.state.mediaUploading}
        onClick={() => {
          document.getElementById(id).click();
        }}
      >
        {title}
      </Button>
    </>
  );
};
const mapStateToProps = (state) => ({
  master: state.master,
});
export default connect(mapStateToProps, null)(UniversityCMS);
