import {
  generateRequiredFormDataFields,
  generateFetchRequest
} from "../../_helper/apiMethods";

export const List = campusUID => {
  let formData = generateRequiredFormDataFields({ signatureKey: campusUID });
  formData.append("campusUID", campusUID);

  let url = "/campus/media/list";
  return generateFetchRequest(formData, url);
};

export const Add = formData => {
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: formData.get("campusUID")
  });

  let url = "/campus/media/add";
  return generateFetchRequest(formData, url);
};

export const Update = formData => {
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: formData.get("campusUID")
  });

  let url = "/campus/media/update";
  return generateFetchRequest(formData, url);
};
