import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { storeUser } from "../_reducers/reducer.user";

import { LoadingIndicator } from "../component";
import { LoginAPI } from "../_api";
import { Typography } from "@material-ui/core";

class DataEntryVerification extends React.Component {
  state = {
    error: false
  };

  async componentDidMount() {
    let { match } = this.props;
    let token = match.params.token;
    await LoadingIndicator.instance.showLoader();
    let response = await LoginAPI.DataEntryLogin(token);
    if (response) {
      this.props.storeUser(response);
      this.props.history.push("/university/dashboard");
    } else this.setState({ error: true });
    LoadingIndicator.instance.hideLoader();
  }

  render() {
    return (
      <div style={{ height: "100vh", padding: 100 }}>
        {this.state.error && (
          <Typography variant="h2" color="secondary">
            Ups! Ada kesalahan.
          </Typography>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      storeUser
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(DataEntryVerification);
