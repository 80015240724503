import {
  generateRequiredFormDataFields,
  generateFetchRequest
} from "../../_helper/apiMethods";
import store from "../../_helper/helper.store";

export const Detail = () => {
  let {
    user: { password }
  } = store.getState();
  let formData = generateRequiredFormDataFields({
    signatureKey: password.universityUID
  });

  formData.append("UID", password.universityUID);

  let url = "/university/detail";
  return generateFetchRequest(formData, url);
};

export const Update = formData => {
  let {
    user: { password }
  } = store.getState();
  formData = generateRequiredFormDataFields({
    formData,
    signatureKey: password.universityUID
  });

  formData.append("uid", password.universityUID);
  let url = "/university/update";
  return generateFetchRequest(formData, url);
};

export const Dashboard = () => {
  let {
    user: { password }
  } = store.getState();
  let formData = generateRequiredFormDataFields({
    signatureKey: password.universityUID
  });
  formData.append("universityUID", password.universityUID);
  let url = "/university/dashboard";
  return generateFetchRequest(formData, url,'v2/');
};
