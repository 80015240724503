import React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { colors } from '@material-ui/core';
import { t } from '../../_language';
import { PageBody, PageTitle, LoadingIndicator } from '../../component';
import { TRANS_STRING } from '../../Const';
import { Grid, Button, Typography } from '@material-ui/core';
import { ReactSelectValidator } from '../../component/validators';

import { CampusAPI, SandBoxAPI } from '../../_api';

const { COMMON_STRING, INPUT_REQ } = TRANS_STRING;
const PAGE_STRING = 'externalAccess.';

class TestExAccess extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedData: {},
      programList: [],
      completed: null,
      campusProgramUID: null,
      studentName: '',
    };
  }

  async componentDidMount() {
    LoadingIndicator.instance.showLoader();
    let { selectedData } = this.props;
    let response = await CampusAPI.Program.List(selectedData.uid);
    if (response) {
      this.setState({
        programList: response.map(({ programName, campusProgramUID }) => ({
          text: programName,
          id: campusProgramUID,
        })),
        selectedData,
      });
    }
    LoadingIndicator.instance.hideLoader();
  }

  handleChange = e => {
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async e => {
    this.setState({ completed: null });
    LoadingIndicator.instance.showLoader(t('apiMessages.saving'));
    let formData = new FormData(e.target);
    formData.append('campusUID', this.state.selectedData.uid);
    let response = await SandBoxAPI.StudentApply(formData);
    if (response) {
      let { triggerRequest, triggerResponse, triggerURL } = response[0];

      this.setState({
        completed: {
          triggerURL,
          triggerResponse: this.getParsedJSON(triggerResponse),
          triggerRequest: this.getParsedJSON(triggerRequest),
        },
      });
    }
    LoadingIndicator.instance.hideLoader();
  };

  getParsedJSON = str => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  };

  onGoback = () => {
    this.props.removeSelected();
  };

  jsonStyle = {
    backgroundColor: colors.grey[300],
  };

  getJsonIndented = obj => (
    <pre style={this.jsonStyle}>{JSON.stringify(obj, null, 2)}</pre>
  );

  render() {
    let { studentName, campusProgramUID, programList, completed } = this.state;
    return (
      <React.Fragment>
        <PageTitle
          title={t(PAGE_STRING + 'test')}
          iconData={[
            {
              icon: (
                <Button size="small" onClick={this.onGoback}>
                  {t('buttons.goback')}
                </Button>
              ),
            },
          ]}
        />
        <PageBody>
          <ValidatorForm onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextValidator
                  label={t(COMMON_STRING + 'studentName')}
                  onChange={this.handleChange}
                  name="studentName"
                  value={studentName}
                  validators={['required']}
                  errorMessages={[t(INPUT_REQ)]}
                />
              </Grid>
              <Grid item xs={6} container alignItems="flex-end">
                <ReactSelectValidator
                  label={t(COMMON_STRING + 'programName')}
                  onChange={this.handleChange}
                  name="campusProgramUID"
                  withID
                  value={campusProgramUID}
                  validators={['required']}
                  errorMessages={[t(INPUT_REQ)]}
                  data={programList}
                />
              </Grid>
              <Grid item xs={6}>
                <Button type="submit">{t('buttons.apply')}</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
          {completed && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Callback URL {completed.triggerURL}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Requrest</Typography>
                {this.getJsonIndented(completed.triggerRequest)}
                <Typography variant="body2">Response</Typography>
                {this.getJsonIndented(completed.triggerResponse)}
              </Grid>
            </Grid>
          )}
        </PageBody>
      </React.Fragment>
    );
  }
}

export default TestExAccess;
