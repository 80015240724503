import React from 'react';
import { Route } from 'react-router-dom';

import TitleBar from './component/TitleBar';
import * as Pages from './pages';
import { NAV_STRINGS } from './Const';
import { MasterAPI } from './_api';
import store from './_helper/helper.store';
import { storeMaster } from './_reducers/reducer.master';

class ProtectedPages extends React.PureComponent {
  async componentDidMount() {
    let masterRes = await MasterAPI.List();
    if (masterRes) {
      store.dispatch(storeMaster(masterRes));
    }
  }

  render() {
    let { match, history } = this.props;
    return (
      <TitleBar history={history}>
        <Route
          exact
          path={`${match.path}/${NAV_STRINGS.dashboard}`}
          component={Pages.Dashboard}
        />
        <Route
          path={`${match.path}/${NAV_STRINGS.studentList}`}
          component={Pages.StudentList}
        />
        <Route
          path={`${match.path}/${NAV_STRINGS.universityCMS}`}
          component={Pages.UniversityCMS}
        />
        <Route
          path={`${match.path}/${NAV_STRINGS.users}`}
          component={Pages.Users}
        />
        <Route
          path={`${match.path}/${NAV_STRINGS.reports}`}
          component={Pages.Reports}
        />
        <Route
          path={`${match.path}/${NAV_STRINGS.changePassword}`}
          component={Pages.ChangePassword}
        />
        {/* <Route
          path={`${match.path}/${NAV_STRINGS.externalAccess}`}
          component={Pages.ExternalAccess}
        /> */}
      </TitleBar>
    );
  }
}

export default ProtectedPages;
