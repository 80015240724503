import { IS_DEVEOPMENT, API_URL } from '../Const';
import { toast } from 'react-toastify';
import store from './helper.store';
import { devLog } from './commonMethods';
var encr = require('crypto-js');
var ip = require('ip');

export const formDataConsole = (formData, url) => {
  if (IS_DEVEOPMENT) {
    console.log('// API Request Body Data', url ? url : '');
    for (var pair of formData.entries()) {
      console.log(pair[0] + ' : ' + pair[1]);
    }
    console.log('// End Request');
  }
};

export const getSignature = (sig) => {
  var wordArray = encr.SHA1(sig);
  var words = wordArray.words;
  var sigBytes = wordArray.sigBytes;
  var hexChars = [];
  for (var i = 0; i < sigBytes; i++) {
    var bite = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
    hexChars.push((bite >>> 4).toString(16));
    hexChars.push((bite & 0x0f).toString(16));
  }
  return hexChars.join('').toUpperCase();
};

export const checkNetworkError = (response) => {
  if (!response.ok || response.status !== 200) {
    throw Error('Network Error');
  }
  return response.json();
};

export const checkAPIResError = (response, shouldShowToast = true) => {
  if (response.status !== '1') {
    return response;
  } else {
    if (shouldShowToast) {
      toast.error(response.message || 'Something Went Wrong');
    }
    return null;
  }
};

export const catchAPIError = (error) => {
  devLog('Error', error);
  toast.error(error.message || 'Something Went Wrong');
  return null;
};

export const resetNullFromResponse = (response) => {
  let newObject = {};
  const mapFunciton = (key) => {
    if (!response[key]) {
      newObject = {
        ...newObject,
        [key]: '',
      };
    } else
      newObject = {
        ...newObject,
        [key]: response[key],
      };
  };
  Object.keys(response).map(mapFunciton);
  return newObject;
};

export const generateRequiredFormDataFields = (
  { formData = new FormData(), signatureKey } = {
    formData: new FormData(),
    signatureKey: null,
  },
) => {
  let {
    password: { userUID, sessionToken },
  } = store.getState().user;
  if (!signatureKey) {
    signatureKey = userUID;
    formData.append('userUID', userUID);
  }
  let signature =
    signatureKey.toLowerCase() + userUID.toLowerCase() + sessionToken;
  devLog('// Raw signature ', signature);

  signature = getSignature(signature);

  formData.append('Signature', signature);
  formData.append('actionBy', userUID);
  formData.append('DeviceIP', ip.address());
  return formData;
};

export const generateFetchRequest = (formData, url, apiVersion = 'v1/') => {
  formDataConsole(formData, url);
  let fullURL = `${API_URL}${apiVersion}${url}`;
  return fetch(fullURL, {
    method: 'POST',
    body: formData,
  })
    .then(checkNetworkError)
    .then((res) => {
      devLog(`//${url} Response`, res);
      return checkAPIResError(res);
    })
    .catch(catchAPIError);
};


