import React from "react";

import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { t } from "../../_language";
import { ReactSelectValidator } from "../../component/validators";
import { UniversityAPI } from "../../_api";
import { Button, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import { TRANS_STRING } from "../../Const";
import { PageTitle, PageBody, LoadingIndicator } from "../../component";
import { LoaderTextType } from "../../component/Loader";
const PAGE_STRING = "user.";
const {
  COMMON_STRING,
  INPUT_REQ,
  API_STRING,
  INPUT_INVALID,
  SAVED_STRING
} = TRANS_STRING;
class UserDetails extends React.Component {
  componentDidMount() {
    let { selectedData } = this.props;
    if (selectedData.uid) {
      this.setState({
        userDetails: {
          ...selectedData
        }
      });
    }
  }

  getInitialState = () => {
    return {
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      role: "",
      active: true,
      locked: false
    };
  };

  state = {
    roleList: [{ text: "Administrator" }, { text: "Manager" }],
    userDetails: this.getInitialState()
  };

  handleChange = e => {
    let { name, value, type } = e.target;
    if (type === "checkbox") {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          [name]: e.target.checked
        }
      });
    } else {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          [name]: value
        }
      });
    }
  };

  handleSubmit = async e => {
    let { userDetails } = this.state;
    await LoadingIndicator.instance.showLoader(LoaderTextType.saving);
    let formData = new FormData(e.target);
    formData.set("active", userDetails.active);
    formData.set("locked", userDetails.locked);
    let response = null;
    if (!userDetails.uid) {
      response = await UniversityAPI.User.Add(formData);
    } else {
      response = await UniversityAPI.User.Update(formData, userDetails.uid);
    }
    if (response) {
      toast.success(t(SAVED_STRING));
      this.props.refreshList();
    } else LoadingIndicator.instance.hideLoader();
  };

  onGoback = () => {
    this.props.removeSelected();
  };

  onPasswarReset = async () => {
    let {
      userDetails: { uid, email }
    } = this.state;
    LoadingIndicator.instance.showLoader();

    let response = await UniversityAPI.User.PasswordReset(uid, email);
    if (response) toast.success(t(API_STRING + "passwordChanged"));
    LoadingIndicator.instance.hideLoader();
  };
  render() {
    let { userDetails, roleList } = this.state;
    return (
      <React.Fragment>
        <PageTitle
          title={t(PAGE_STRING + "detailsTitle")}
          iconData={[
            {
              icon: (
                <Button size="small" onClick={this.onGoback}>
                  {t("buttons.goback")}
                </Button>
              )
            }
          ]}
        />
        <PageBody>
          <ValidatorForm onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextValidator
                  label={t(COMMON_STRING + "firstName")}
                  onChange={this.handleChange}
                  name="firstName"
                  value={userDetails.firstName}
                  validators={["required"]}
                  errorMessages={[t(INPUT_REQ)]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  label={t(COMMON_STRING + "lastName")}
                  onChange={this.handleChange}
                  name="lastName"
                  value={userDetails.lastName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  label={t(COMMON_STRING + "email")}
                  onChange={this.handleChange}
                  name="email"
                  value={userDetails.email}
                  validators={["required", "isEmail"]}
                  errorMessages={[t(INPUT_REQ), t(INPUT_INVALID)]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  label={t(COMMON_STRING + "mobileNo")}
                  onChange={this.handleChange}
                  name="mobileNo"
                  value={userDetails.mobileNo}
                />
              </Grid>
              <Grid item xs={6}>
                <ReactSelectValidator
                  label={t(COMMON_STRING + "role")}
                  value={userDetails.role}
                  name="role"
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={[t(INPUT_REQ)]}
                  data={roleList}
                />
              </Grid>
            </Grid>
            <Grid container>
              <FormControlLabel
                control={
                  <Checkbox
                    name="locked"
                    checked={userDetails.locked}
                    onChange={this.handleChange}
                  />
                }
                label={t(COMMON_STRING + "locked")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    checked={userDetails.active}
                    onChange={this.handleChange}
                  />
                }
                label={t(COMMON_STRING + "active")}
              />
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs />
              <Grid item xs={3} container justifyContent="flex-end">
                {userDetails.uid && (
                  <Button type="button" onClick={this.onPasswarReset}>
                    {t("buttons.resetPassword")}
                  </Button>
                )}
              </Grid>
              <Grid item xs={1}>
                <Button type="submit">{t("buttons.save")}</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </PageBody>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  master: state.user.master
});
export default connect(
  mapStateToProps,
  null
)(UserDetails);
