import React from "react";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

const Widget = ({ title, value }) => {
  return (
    <Paper
      style={{
        padding: 15,
        backgroundColor: "#5E35B1",
        color: "#FFF",
        minHeight: 175,
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column"
      }}
    >
      <Typography variant="body2" align="center" style={{ color: "#fff" }}>
        {title}
      </Typography>
      <Typography
        variant="h2"
        style={{ color: "#fff", overflowWrap: "break-word" }}
        align="center"
      >
        {value}
      </Typography>
    </Paper>
  );
};
export default Widget;
