export const subTitle = {
  beasiswa: "Cari & Daftar Beasiswa Mudah",
  daftarkuliah: "Daftar Kuliah & Universitas Hanya 1x Isi Form",
  event: "Atur Jadwal & Bikin Event Kampus Jadi Gampang",
  danapendidikan: "Cari Pinjaman Dana Pendidikan Online",
  magang: "Cari Magang & Internship Mahasiswa",
  studentpromo: "Promo Makanan & Minuman Mahasiswa",
  privacypolicy: "Kebijakan Privasi",
  termsofuse: "Syarat Penggunaan",
  universitas: "Keuntungan goKampus Bagi Universitas",
  keuntunganpelajar: "Keuntungan goKampus Bagi Siswa"
  // detailuniversity: "Univ Details",
  // detailblog: "Bolg Detail",
  // detailpromo: "Promo Detail",
  // emailverification: "Email Verification",
  // "events/attendee": "Event Attendee",
  // underconstruction: "UnderConstruction",
  // aboutus: "About Us",
  // pricing: "Pricing",
  // blog: "Blog",
  // unsubscribenewsletter: "unsubscribenewsletter",
  // detailevent: "Event Detail",
  // searchevent: "Search Event",
  // deverify: "deverify",
  // dashboard: "Dashboard",
  // applytouniversity: "Apply",
  // topup: "Topup",
  // promodetail: "Promo Details",
  // eazytuition: "Easy Tuition",
  // studentpromo: "Student Promo",
  // appliedprogram: "Applied Programs",
  // eventcreation: "Event Create",
  // setting: "Settings",
  //university
  // dashboard: "Dashboard",
  // studentlist: "Student List",
  // universitycms: "University CMS",
  // user: "User",
  // reports: "Reports",
  // changepassword: "Change Password",
  // externalaccess: "External Access"
};
export const pageTitle = "goKampus";
export const homePageTitle = `${pageTitle} - 1 App Urusan Kampus Beres`;
